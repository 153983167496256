import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';

import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowTurnDownLeft,
  faArrowUpRightFromSquare,
  faDollarSign,
  faLock,
  faPencilMechanical,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OptionsAccordion } from '@v2/components/OptionsAccordion';
import {
  AdCapDurationLabelKeys,
  IncrementalOptionsLabelKeys,
} from '@v2/components/forms/AdvancedDeliveryOptionsForm/constants';
import TypographyField from '@v2/components/forms/CampaignForm/TypographyField';
import { BudgetTextField } from '@v2/components/ui/BudgetTextField';
import { Button } from '@v2/components/ui/Button';
import { SelectDate } from '@v2/components/ui/SelectDate';
import { SelectTime } from '@v2/components/ui/SelectTime';
import { entityStatus } from '@components/CampaignWizard/constants';
import { SectionInfo } from '@components/SectionInfo';
import { fields, sections } from './constants';
import { useSectionsInfo } from './hooks/useSectionsInfo';
import { useCampaignForm } from './hooks/useCampaignForm';

const dateFields = ['startDate', 'startTime', 'endDate', 'endTime'];

const StyledFormContainer = styled(Box)(({ theme }) => ({
  maxWidth: theme.spacing(67), // 353px wide form using theme.spacing
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));

const StyledLockIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[6],
}));

const CampaignForm = props => {
  const {
    ObjectiveOptionArgs,
    onAdvancedCampaignDeliveryOptionsClick,
    onSubmit,
    onFormChange,
    onRetargetDisplayToggleChange,
    campaignStaticDisplayAdGroups,
    ...rest
  } = props;

  const {
    control,
    handleSubmit,
    formState,
    watch,
    trigger,
    setValue,
    reset,
  } = useFormContext();

  const { isValid, dirtyFields } = formState;

  const [
    budgetType,
    startDate,
    startTime,
    endDate,
    endTime,
    advancedOptions,
    status,
  ] = watch(['budgetType', ...dateFields, 'advancedOptions', 'status']);

  const {
    isIncremental,
    isExperiment,
    isCapAds,
    experimentType,
    experimentPlan,
    freqCaps,
  } = advancedOptions || {};

  const {
    AdvancedDeliveryOptionsFormCopy,
    Copy,
    dragsAllowed,
    dragToggleDisabled,
    dragToggleText,
    dragTooltip,
    minEndDate,
  } = useCampaignForm({
    campaignStaticDisplayAdGroups,
    endDate,
    isIncremental,
    startDate,
  });

  const handleBudgetTypeChange = (event, newAlignment, budgetTypeField) => {
    if (newAlignment === null) return;

    const name = budgetTypeField.value.split('-');
    setValue(`${name[0]}_campaign_budget`, '', { shouldValidate: true });
    budgetTypeField.onChange(newAlignment);
  };

  // set time to default if the selected date is in the future
  const setDefaultTime = (selectedDate, path, defaultTime) => {
    const currentDate = moment().startOf('day');
    if (currentDate.isBefore(selectedDate)) {
      setValue(
        path,
        defaultTime ?? moment().startOf('day'),
        { shouldValidate: true }
      );
    }
  }

  const handleCancel = () => {
    reset();
  };

  useEffect(() => {
    onFormChange?.({
      formState,
      reset,
      trigger,
    });
  }, [formState, reset, trigger]);

  useEffect(() => {
    if (dateFields.some(field => dirtyFields[field])) {
      trigger(dateFields);
    }
  }, [startDate, endDate, startTime, endTime, trigger]);

  const { info } = useSectionsInfo();

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="on"
      {...rest}
    >
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} md={12}>
          <StyledFormContainer>
            {' '}
            {/* 2rem spacing */}
            <Controller
              name={fields.name.path}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  id="campaign-name"
                  inputProps={{
                    'data-testid': 'campaign-name',
                  }}
                  label="Campaign Name"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name={fields.advertiserName.path}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  id="advertiser-name"
                  label="Advertiser Name"
                  disabled
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FontAwesomeIcon icon={faLock} />
                      </InputAdornment>
                    ),
                    'data-testid': 'advertiser-name',
                  }}
                  variant="outlined"
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name={fields.budgetType.path}
              control={control}
              render={({ field: budgetTypeField }) => (
                <ToggleButtonGroup
                  {...budgetTypeField}
                  color="primary"
                  exclusive
                  onChange={(event, newAlignment) =>
                    handleBudgetTypeChange(
                      event,
                      newAlignment,
                      budgetTypeField,
                    )
                  }
                  aria-label="Budget Type"
                >
                  <ToggleButton value="lifetime_budget">
                    <Box component="span">Lifetime Budget</Box>
                    <Box component="span" sx={{ ml: 1 }}>
                      <Tooltip
                        title="Lifetime budget info"
                        placement="top"
                        arrow
                      >
                        <StyledLockIcon icon={faCircleInfo} />
                      </Tooltip>
                    </Box>
                  </ToggleButton>
                  <ToggleButton value="daily_budget">
                    <Box component="span">Daily Budget</Box>
                    <Box component="span" sx={{ ml: 1 }}>
                      <Tooltip
                        title="Daily budget info"
                        placement="top"
                        arrow
                      >
                        <StyledLockIcon icon={faCircleInfo} />
                      </Tooltip>
                    </Box>
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
            <Grid container spacing={1}>
              {budgetType === 'lifetime_budget' && (
                <Grid item xs={6}>
                  <Controller
                    name={fields.lifetimeBudget.path}
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => {
                      return (
                        <TextField
                          {...field}
                          id="lifetime-campaign-budget"
                          key="lifetime-campaign-budget"
                          label="Lifetime Campaign Budget"
                          placeholder="Enter Campaign Budget"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FontAwesomeIcon icon={faDollarSign} />
                              </InputAdornment>
                            ),
                            inputProps: { min: 0 },
                          }}
                          variant="outlined"
                          error={invalid}
                          helperText={error?.message}
                        />
                      );
                    }}
                  />
                </Grid>
              )}

              {budgetType === 'lifetime_budget' && (
                <Grid item xs={6}>
                  <Controller
                    name={fields.dailyBudgetCap.path}
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <BudgetTextField
                        {...field}
                        id="daily-budget-cap"
                        label="Daily Budget Cap"
                        placeholder="Daily Budget Cap (optional)"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon icon={faDollarSign} />
                            </InputAdornment>
                          ),
                          inputProps: { min: 0 },
                        }}
                        variant="outlined"
                        error={invalid}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
              )}

              {budgetType === 'daily_budget' && (
                <Grid item xs={12}>
                  <Controller
                    name={fields.dailyBudget.path}
                    control={control}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <BudgetTextField
                        {...field}
                        id="daily-campaign-budget"
                        key="daily-campaign-budget"
                        label="Daily Campaign Budget"
                        placeholder="Enter Campaign Budget"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon icon={faDollarSign} />
                            </InputAdornment>
                          ),
                          inputProps: { min: 0 },
                        }}
                        variant="outlined"
                        error={invalid}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Stack direction="column" spacing={3}>
              <SectionInfo infoBlocks={info} sub={sections.dates} />
              <Stack direction="row" spacing={3} xs={6}>
                <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
                  <Controller
                    name={fields.startDate.path}
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectDate
                        {...field}
                        onChange={e => {
                          field.onChange(e);
                          setDefaultTime(e, fields.startTime.path);
                        }}
                        disabled={status === entityStatus.active}
                        fieldState={fieldState}
                        id="start-date"
                        label="Start Date"
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
                  <Controller
                    name={fields.startTime.path}
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectTime
                        {...field}
                        disabled={status === entityStatus.active}
                        fieldState={fieldState}
                        id="start-time"
                        label="Start Time"
                      />
                    )}
                  />
                </FormControl>
              </Stack>

              <Stack direction="row" spacing={3} xs={6}>
                <FormControl fullWidth variant="outlined">
                  <Controller
                    name={fields.endDate.path}
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectDate
                        {...field}
                        minDate={minEndDate}
                        fieldState={fieldState}
                        id="end-date"
                        label="End Date"
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <Controller
                    name={fields.endTime.path}
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectTime
                        {...field}
                        disabled={!endDate}
                        fieldState={fieldState}
                        id="end-time"
                        label="End Time"
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            </Stack>
            {dragsAllowed && (
              <Box>
                <Controller
                  name={fields.displayPct.path}
                  control={control}
                  render={({ field: displayPctField }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...displayPctField}
                          checked={displayPctField.value}
                          disabled={dragToggleDisabled}
                          id="retarget-switch"
                          name="displayPct"
                          onChange={event =>
                            onRetargetDisplayToggleChange(
                              event,
                              displayPctField,
                            )
                          }
                        />
                      }
                      label={
                        <>
                          {dragToggleText}
                          <Tooltip
                            title={dragTooltip}
                            placement="top"
                            arrow
                          >
                            <IconButton size="small">
                              <FontAwesomeIcon icon={faCircleInfo} />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    />
                  )}
                />
              </Box>
            )}
            <Controller
              control={control}
              name={fields.objective.path}
              render={({ field }) => (
                <OptionsAccordion
                  {...ObjectiveOptionArgs}
                  {...field}
                  onChange={(_, __, option) => {
                    field.onChange(option.value);
                  }}
                />
              )}
            />
            <Box>
              <Button
                label={Copy.advancedOptionsLabel}
                icon={faArrowUpRightFromSquare}
                onClick={onAdvancedCampaignDeliveryOptionsClick}
                size="small"
                variant="text"
                sx={{ ml: -1 }}
              />

              {(isIncremental || isExperiment || isCapAds) && (
                <Box component="ul" sx={{ my: 1 }}>
                  {isIncremental && (
                    <li>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <TypographyField
                          variant="text"
                          control={control}
                          name={`${fields.advancedOptions.path}.experimentType`}
                        >
                          <strong>{Copy.incrementalityLabel}</strong>{' '}
                          {IncrementalOptionsLabelKeys[experimentType]}
                        </TypographyField>

                        <FontAwesomeIcon icon={faPencilMechanical} />
                      </Stack>
                    </li>
                  )}

                  {isExperiment && (
                    <li>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <TypographyField
                          variant="text"
                          control={control}
                          name={`${fields.advancedOptions.path}.experimentPlan`}
                        >
                          <strong>{Copy.experimentLabel}</strong>{' '}
                          {AdvancedDeliveryOptionsFormCopy[experimentPlan]}
                        </TypographyField>

                        <FontAwesomeIcon icon={faPencilMechanical} />
                      </Stack>
                    </li>
                  )}

                  {isCapAds &&
                    freqCaps?.map(
                      ({ impressions, duration, fctype }, index) => (
                        <li key={index}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <TypographyField
                              variant="text"
                              control={control}
                              name={`${fields.advancedOptions.path}.freqCaps`}
                            >
                              <strong>{Copy.frequencyCapLabel}</strong> {impressions}{' '}
                              ads every {duration}{' '}
                              {AdCapDurationLabelKeys[fctype]}
                            </TypographyField>

                            <FontAwesomeIcon icon={faPencilMechanical} />
                          </Stack>
                        </li>
                      ),
                    )}
                </Box>
              )}
            </Box>
          </StyledFormContainer>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              data-testid="cancel-button"
              id="cancel-button"
              name="cancel_action"
              variant="text"
              iconAlign="right"
              label="Cancel"
              onClick={handleCancel}
            />
            <Button
              data-testid="next-button"
              id="next-button"
              name="next_action"
              type="submit"
              icon={faArrowTurnDownLeft}
              iconAlign="right"
              label="Next: Ad Group 1"
              disabled={!isValid}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

// Define PropTypes
CampaignForm.propTypes = {
  campaignStaticDisplayAdGroups: PropTypes.array,
  onAdvancedCampaignDeliveryOptionsClick: PropTypes.func.isRequired,
  onRetargetDisplayToggleChange: PropTypes.func.isRequired,
  ObjectiveOptionArgs: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormChange: PropTypes.func,
};

CampaignForm.displayName = 'Campaign';

export default memo(CampaignForm);
