import { geoUrl } from '../../../../components/hooks/geo';
import { distinctNames } from './constants';

const validateZipCodeWithMapBox = async zip => {
  try {
    const response = await fetch(geoUrl(zip)).then(res => res.json());
    return response.features.length > 0;
  } catch (error) {
    return false;
  }
};

export const validateCodes = codes => {
  return Promise.all(
    codes.map(async ({ zip }) => {
      if (zip.length !== 5 || isNaN(zip)) {
        return Promise.resolve({ zip, valid: false });
      }
      const valid = await validateZipCodeWithMapBox(zip);
      return Promise.resolve({ zip, valid });
    }),
  );
};

export const idToKey = id => {
  const [name] = id.split('.');

  return distinctNames[name];
};

export const distinctToType = distinct => {
  if (/\(DMA\)/.test(distinct.text)) {
    return distinctNames.dma;
  }

  return idToKey(distinct.id);
};

export const getActualField = value => {
  if (!value) return '';

  if (!value.place_name) {
    return value.text ?? value.name ?? '';
  }

  if (value.place_name.endsWith('(DMA)')) {
    return value.place_name;
  }

  const splitted = value.place_name.split(',');
  const placeName = splitted.slice(0, splitted.length - 1).join(',');

  if (value.id.startsWith('place')) {
    return `${placeName} (City)`;
  }

  if (value.id.startsWith('region')) {
    return `${placeName} (State)`;
  }

  return placeName;
};

export const getActualFieldPlain = value =>
  value.place_name ?? value.name ?? value.text ?? '';

export const findOption = (options, value) =>
  options.find(o => getActualField(o) === getActualField(value));
