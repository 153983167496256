import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState, useContext } from 'react';
import { useUser } from '@components/hooks';
import AdvertiserContext from '@components/AdvertiserContext';
import { Themes } from '@constants/themes';
import {
  customInventoryTabValues,
  defaultCustomInventoryTabs,
  padmanCustomInventoryTabs,
} from '../../constants';
import { ReactiveIconStyled } from '../../styles';
import { useAdvancedGeneralTable } from './useAdvancedGeneralTable';
import { useTabs } from './useTabs';
import { getNonEmptyArrayValidation } from './validation';
import { useAdvancedCustomInventoryData } from './useAdvancedCustomInventoryData';

export const useAdvancedCustomInventoryTable = data => {
  const { items, selected } = data;
  const { user } = useUser();
  const { theme } = useContext(AdvertiserContext);
  const customInventoryTabs = theme === Themes.NBCU ?
    padmanCustomInventoryTabs :
    defaultCustomInventoryTabs;
  const [groupByFieldName, setGroupByFieldName] = useState();

  const filteredTabs = useMemo(() => {
    const isInternal = user.is_tvsci_employee;
    return customInventoryTabs.filter(tab => isInternal || !tab.internalOnly);
  }, [user]);

  const { tab, tabs, setTab } = useTabs(filteredTabs);

  const filteredData = useMemo(() => {
    switch (tab) {
      case customInventoryTabValues.dealId:
      case customInventoryTabValues.appListId:
      case customInventoryTabValues.appName:
        // TODO: Filter items for these tabs once API is returning them
        return [];
      case customInventoryTabValues.inventory:
        setGroupByFieldName('type');
        return items;
      case customInventoryTabValues.channels:
      default:
        setGroupByFieldName('');
        return items.filter(i => {
          return i.type === 'Network';
        });
    }
  }, [items, tab]);

  const legend = useMemo(() => {
    const legendList = [];

    if (filteredData.some(i => i.featured)) {
      legendList.push({
        type: 'color',
        value: 'chart.6',
        label: `Featured ${tab}`,
      });
    }

    if (filteredData.some(i => i.recommended)) {
      legendList.push({
        type: 'node',
        label: `Recommended ${tab}`,
        value: <ReactiveIconStyled name="reactive" />,
      });
    }

    return legendList;
  }, [filteredData, tab]);

  const tableProps = useAdvancedGeneralTable({
    data: filteredData,
    selected,
    dataFieldName: 'display_name',
    groupByFieldName,
    resolver: () => yupResolver(getNonEmptyArrayValidation()),
    featuredFieldName: 'Featured Genres',
  });

  // For the "App Name", "App List ID", and "Deal ID" tabs we have to filter remotely
  const inventoryData = useAdvancedCustomInventoryData({
    tab,
    selected: tableProps.selected,
  });

  return {
    ...tableProps,
    ...inventoryData,
    legend,
    tab,
    tabs,
    setTab,
  };
};
