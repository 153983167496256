import { Themes } from '@constants/themes';

export const Copies = {
  [Themes.DEFAULT]: {
    campaignObjectiveHeader: 'Select a campaign objective',
    objectiveRecommendation: 'Recommended based on your advertiser type',
  },
  [Themes.NBCU]: {
    campaignObjectiveHeader: 'Select a Campaign Objective',
    objectiveRecommendation: 'Recommended Based On Your Advertiser Type',
  },
};

export const objectives = {
  appInstalls: {
    value: 'appInstalls',
    label: 'App Installs',
    description: 'Drive new users to download your app from the app store.',
    infoText:
      "Increase the number of times your app is downloaded and installed on users' devices.",
    lockedInfo:
      'This bid strategy will become available once tracking is set up.',
  },
  websiteSales: {
    value: 'websiteSales',
    label: 'Website Sales',
    description: 'Encourage users to visit your website and make a purchase.',
    infoText:
      'Drive online sales conversions by directing users to your website and encouraging them to buy your products or services.',
    lockedInfo:
      'This bid strategy will become available once tracking is set up.',
  },
  websiteVisits: {
    value: 'websiteVisits',
    label: 'Website Visits',
    description: 'Increase website traffic and engagement with your brand.',
    infoText:
      'Drive more users to visit specific pages on your website, increasing brand awareness and potential customer engagement.',
    lockedInfo:
      'This bid strategy will become available once tracking is set up.',
  },
  audienceReach: {
    value: 'audienceReach',
    label: 'Audience Reach',
    description: 'Broaden your audience and increase brand awareness.',
    infoText:
      'Expand the number of unique people who see your ad campaign, maximizing brand exposure to a wider audience.',
    content: '',
    lockedInfo:
      'This bid strategy will become available once tracking is set up.',
  },
};

export const objectivesOptions = ({
  recommendedCampaignObjective,
  disabledObjectiveOptions,
  copies,
}) => [
  // Example objectives demonstrating various configurations
  {
    ...objectives.appInstalls,
    recommended: recommendedCampaignObjective === 'appInstalls',
    recommendedInfo: copies.objectiveRecommendation,
    locked: disabledObjectiveOptions.appInstalls,
  },
  {
    ...objectives.websiteSales,
    recommended: recommendedCampaignObjective === 'websiteSales',
    recommendedInfo: copies.objectiveRecommendation,
    locked: disabledObjectiveOptions.websiteSales,
  },
  {
    ...objectives.websiteVisits,
    recommended: recommendedCampaignObjective === 'websiteVisits',
    recommendedInfo: copies.objectiveRecommendation,
    locked: disabledObjectiveOptions.websiteVisits,
  },
  {
    ...objectives.audienceReach,
    recommended: recommendedCampaignObjective === 'audienceReach',
    recommendedInfo: copies.objectiveRecommendation,
    locked: disabledObjectiveOptions.audienceReach,
  },
];
