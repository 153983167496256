import { useContext, useEffect } from 'react';

import { useAPI } from './api';
import { useCurrentSession } from './currentSession';
import AdvertiserContext from '../AdvertiserContext';
import AdvertisersContext from '../../providers/AdvertisersContext';

// TODO: Create Advertisers context to keep advertisers globally and request data once from the App component
//////////////////////////
// useAdvertisers HOOK
//////////////////////////
export const useAdvertisers = () => {
  const advertisersContext = useContext(AdvertisersContext);
  const {
    currentAdvertiser,
    setCurrentAdvertiser,
    updateCurrentAdvertiser: updateCurrentSessionAdvertiser,
  } = useCurrentSession();
  const adContext = useContext(AdvertiserContext);
  const { useGet, usePatch, usePost } = useAPI();

  if (!advertisersContext) {
    throw new Error(
      'useAdvertisers AdvertisersContext was used outside of its Provider',
    );
  }
  const { advertisers, fetchingAdvertisers, dispatchAdvertisers } =
    advertisersContext;

  const cachedId = parseInt(localStorage.getItem('AdvertiserContext'), 10);

  // After advertisers are called set current advertiser
  useEffect(() => {
    if (advertisers === null && cachedId) {
      updateCurrentSessionAdvertiser({ id: cachedId });
      adContext.updateAdvertiser({
        id: cachedId,
      });

      return;
    }

    const advertiser = cachedId
      ? advertisers?.find(a => a.id === cachedId)
      : advertisers?.[0];

    if (advertiser) {
      setCurrentAdvertiser(advertiser);
    }
  }, [advertisers]);

  useEffect(() => {
    if (!currentAdvertiser?.id) {
      return;
    }

    const {
      active,
      billing_method,
      bidstrategyevent_set,
      bidstrategy_set,
      category,
      cost_model,
      deductive_exposure_lid: exposureLid,
      deductive_outcome_lid: outcomeLid,
      default_payment_profile,
      domain,
      has_incrementality,
      id,
      invoice_approved,
      looker_experience,
      looker_validated,
      name,
      path_to_purchase_validated,
      cs_owner,
      primary_org,
      url,
      tvsciq,
      org_role,
      adv_role,
      billing_account_is_valid,
      has_sequential_events,
    } = currentAdvertiser;

    // Add Advertiser Context ID to localStorage
    localStorage.setItem('AdvertiserContext', id);

    // Update the App Advertiser Context
    adContext.updateAdvertiser({
      active,
      billing_method,
      bidstrategyevent_set,
      bidstrategy_set,
      category,
      cost_model,
      default_payment_profile,
      domain,
      exposureLid,
      has_incrementality,
      id,
      invoice_approved,
      looker_experience,
      looker_validated,
      name,
      outcomeLid,
      path_to_purchase_validated,
      cs_owner,
      primary_org,
      url,
      tvsciq,
      org_role,
      adv_role,
      billing_account_is_valid,
      has_sequential_events,
    });
  }, [currentAdvertiser]);

  // Adds https protocol to user inputted domain
  const formatAdvertiserUrl = url => {
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      return url.trim();
    }

    return `https://${url}`.trim();
  };

  // Checks for unsecure protocol
  const validateAdvertiserUrl = url => !(url.indexOf('http://') === 0);

  // TODO: use Regex to parse url string
  const verifyRootDomain = url => {
    let protocol = null;

    if (url.includes('http://')) {
      protocol = 'http://';
    } else if (url.includes('https://')) {
      protocol = 'https://';
    }

    if (protocol != null) {
      const split = url.split(protocol);
      const str = split[1] ? split[1] : null;

      if (str != null) {
        const urlArray = str.split('/');
        const filtered = urlArray.filter(
          a => a !== '' && !a.includes('http'),
        );
        return filtered.length === 1;
      }
    }

    const domain = url.split('/');
    const filtered = domain.filter(a => a !== '');
    return filtered.length === 1;
  };

  const setDefaultPayment = async url =>
    await usePatch(`/advertisers/${adContext.id}/`, {
      default_payment_profile: url,
    });

  // Exposed function for updating the
  // current advertiser and advertiser context
  const updateCurrentAdvertiser = advertiser => {
    setCurrentAdvertiser(advertiser);

    if (adContext.id) {
      return useGet('/advertisers/me/');
    }
  };

  const createAdvertiser = data => {
    const { name } = currentAdvertiser;

    const dataObj = {
      name,
      ...data,
    };

    return usePost('/advertisers/', dataObj)
      .then(response => {
        if (response.statusText === 'Created') {
          updateCurrentAdvertiser(response.data);
          dispatchAdvertisers({ type: 'add', payload: [response.data] });
          return response.data;
        }

        return response;
      })
      .catch(error => {
        console.warn(error);
        return error;
      });
  };

  const updateAdvertiser = (id, data) => {
    return usePatch(`/advertisers/${id}`, data).then(response => {
      dispatchAdvertisers({ type: 'replace', payload: response.data });
      return response.data;
    });
  };

  // Expose the functions/variables
  return {
    advertisers,
    fetchingAdvertisers,
    createAdvertiser,
    updateAdvertiser,
    currentAdvertiser,
    setCurrentAdvertiser,
    updateCurrentAdvertiser,
    formatAdvertiserUrl,
    validateAdvertiserUrl,
    verifyRootDomain,
    setDefaultPayment,
  };
};
