import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { creativeTabs, fields } from '../../constants';
import { useCreativeContext } from '../../hooks/useCreativeContext';
import { getColumns } from '../constants';
import { escapeRegExp } from 'lodash';

export const useLibrary = ({ onClose }) => {
  const { control, watch, setValue } = useFormContext();
  const { library, drop, setTab, toggleAssignCreative, cancelUploading } =
    useCreativeContext();

  const [search, creatives, files] = watch([
    fields.search.path,
    fields.creatives.path,
    fields.files.path,
  ]);

  const actualLibrary = [...files, ...library];

  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  // Update search value using setValue from react-hook-form
  const setSearch = (value) => {
    setValue(fields.search.path, value);
  };

  const filteredLibrary = useMemo(() => {
    const re = new RegExp(escapeRegExp(search), 'i');
    return actualLibrary.filter(v => {
      return re.test(v.name);
    });
  }, [search, actualLibrary]);

  const handleSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const columns = useMemo(
    () =>
      getColumns({
        onToggleAssign: toggleAssignCreative,
        assignedCreatives: creatives,
        onPreviewCreative: () => {},
        onUploadingCancel: cancelUploading,
      }),
    [creatives, toggleAssignCreative, cancelUploading],
  );

  const handleCancel = () => {
    onClose();
  };

  const handleDrop = files => {
    drop(files, preFetchedFiles => {
      const ids = preFetchedFiles.map(({ id }) => id);
      setTab({
        key: creativeTabs[1].value,
        value: ids,
      });
    });
  };

  const sortedLibrary = useMemo(() => {
    const orderKeys = {
      status: 'active',
    };
    if (!orderBy) {
      return filteredLibrary;
    }
    const key = orderKeys[orderBy] ?? orderBy;
    return filteredLibrary.sort((a, b) => {
      if (order === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      }
      return a[key] < b[key] ? 1 : -1;
    });
  }, [filteredLibrary, order, orderBy]);

  return {
    library: sortedLibrary,
    order,
    setTab,
    orderBy,
    creatives,
    files,
    showDropzone: !actualLibrary.length,
    isUploading: !!files.length,
    uploaded: files.filter(f => f.progress === 100).length,
    sort: handleSort,
    drop: handleDrop,
    control,
    columns,
    cancel: handleCancel,
    search,
    setSearch,
  };
};
