import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@mui/material';

const CreativeTableHead = ({ headers }) => (
  <TableHead>
    <TableRow>
      {headers.map((headCell) => (
        <TableCell
          key={headCell.id}
          align="left"
          padding={headCell.disablePadding ? 'none' : 'default'}
        >
          {headCell.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

CreativeTableHead.propTypes = {
  headers: PropTypes.array.isRequired
};

export default CreativeTableHead;
