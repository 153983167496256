import React, { Suspense } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useParams, useRouteMatch } from 'react-router-dom';
import { SWRConfig } from 'swr';

import AppHeader from '../../AppHeader';

import { RoutePaths } from '../../../constants';
import { WizardNavigationContextProvider } from '../../../providers/WizardNavigationContext';
import { logger, useNotification } from '../../../swr';
import { defaultTheme_v2, padmanTheme_v2 } from '../../../themes';
import CampaignWizard from '../../CampaignWizard';
import { CampaignWizardContextProvider } from '../../CampaignWizard/CampaignWizardContext';
import { useDomain } from '@components/hooks/domain';

function VerticalCampaignFlowPage() {
  const { campaignId, adGroupId } = useParams();
  const isAddNewAdGroupAction = useRouteMatch(
    RoutePaths.CAMPAIGN_ADGROUPS_NEW,
  );

  const domain = useDomain();

  const action = isAddNewAdGroupAction ? 'add' : '';

  const isPeacockDomain = domain.peacock;
  const theme = isPeacockDomain ? padmanTheme_v2 : defaultTheme_v2;

  return (
    // NOTE: See https://swr.vercel.app/docs/global-configuration#nesting-configurations
    // NOTE: See https://swr.vercel.app/docs/api#options
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SWRConfig
          value={{
            use: [logger, useNotification],
            suspense: true,
            revalidateOnFocus: false,
            revalidateOnMount: false,
          }}
        >
          <AppHeader>
            <Suspense fallback={<div>loading campaign wizard...</div>}>
              <CampaignWizardContextProvider
                campaignId={campaignId ? Number(campaignId) : undefined}
                adGroupId={adGroupId ? Number(adGroupId) : undefined}
                action={action}
              >
                <WizardNavigationContextProvider>
                  <CampaignWizard />
                </WizardNavigationContextProvider>
              </CampaignWizardContextProvider>
            </Suspense>
          </AppHeader>
        </SWRConfig>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default VerticalCampaignFlowPage;
