import { get, isArray, isObject } from 'lodash';
import { entityStatus } from './constants';

export const removeNullValues = (obj = {}) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === null || value === undefined) {
      return acc;
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

export const convertToStatus = entity => {
  if (!entity) {
    return entityStatus.pending;
  }

  if (entity.draft) {
    return entityStatus.draft;
  }

  if (!entity.active && !entity.draft) {
    return entityStatus.paused;
  }

  return entityStatus.active;
};

const processErrors = (
  errors,
  { generalError, setError, generalErrorFields = [], mappedFields = {} },
) => {
  Object.keys(errors).forEach(key => {
    if (
      generalErrorFields?.includes(key)
    ) {
      generalError?.(errors[key][0]);
    }

    const error = errors[key];

    if (isObject(error) && !isArray(error)) {
      processErrors(error, { generalError, setError, generalErrorFields });
      return;
    }

    setError(mappedFields[key] ?? key, {
      type: 'server',
      message: errors[key][0],
    });
  });
};

export const handleErrors =
  (
    cb,
    { setError, generalError, generalErrorFields = [], mappedFields = {} },
  ) =>
  async (...params) => {
    try {
      return await cb(...params);
    } catch (error) {
      const errors = error?.response?.data;
      if (errors) {
        processErrors(errors, {
          generalError,
          setError,
          generalErrorFields,
          mappedFields,
        });
      } else {
        throw error;
      }
    }
  };

export const getValues = (data = {}, keys = []) =>
  keys.reduce((acc, key) => [...acc, get(data, key)], []);
