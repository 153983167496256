import React, { useContext, useEffect, useMemo } from 'react';
import {
  Switch,
  useHistory,
  useRouteMatch,
  matchPath
} from 'react-router-dom';
import { setUserVars, identify } from '@fullstory/browser';
import 'fontsource-noto-sans-jp';

import {
  useAdvertisers,
  useIntercom,
  usePermissions,
  useSSOUser,
  useOrg,
  useUser,
  useAuth,
  useFlags,
  useTenant,
  termsAndConditionsDialog,
  useUniqueLocationKey,
} from './hooks';
import { useAppDeployment } from './hooks/appDeployment';
import { useGetLatestTenantTermAndCondition } from './hooks/apis/termsAndConditions';
import { usePostUserAgreement } from './hooks/apis/userAgreements';
import AdvertiserContext from './AdvertiserContext';
import DialogContext from '../providers/DialogContext';
import { OrganizationRoles, RoutePaths, Scopes } from '../constants';
import { useInterceptors } from '../interceptors';
import { createRoutes } from '../helpers';

const App = () => {
  const history = useHistory();
  const key = useUniqueLocationKey();
  const { authState } = useAuth();
  const adContext = useContext(AdvertiserContext);
  const { setDialog } = useContext(DialogContext);
  const { user, getUser } = useUser();
  const { hasPermission, arePermissionsLoaded } = usePermissions();
  const { currentAdvertiser, advertisers } = useAdvertisers();
  const { getSSOUser } = useSSOUser();
  const { org } = useOrg();
  const tenant = useTenant();
  const { theme } = useAppDeployment({
    currentAdvertiserId: adContext?.id,
    orgTenant: org?.primary_tenant,
  });
  const { flags, isFlagsLoading } = useFlags();
  const termsAndConditionsMatch = useRouteMatch(RoutePaths.TERMS_AND_CONDITIONS);

  const { data: latestTenantTermAndCondition } = useGetLatestTenantTermAndCondition(tenant.id);
  const { trigger: postUserAgreementTrigger } = usePostUserAgreement();

  const isTenantAdmin = useMemo(
    () => org?.organization_role === OrganizationRoles.TENANT_ADMIN,
    [org?.organization_role]
  );

  const { isAuthenticated } = authState;
  const canAccessApplication = hasPermission([Scopes.CAN_ACCESS_APPLICATION], true);
  const canViewCampaignSetup = hasPermission([Scopes.CAN_CREATE_CAMPAIGN]);
  const canUpdateCampaigns = hasPermission([Scopes.CAN_UPDATE_CAMPAIGN]);
  const canViewReports = true;
  const canViewBusinessManager = hasPermission([
    Scopes.CAN_VIEW_BUSINESS_MANAGER,
  ]);
  const shouldSetupAccount = hasPermission([
    Scopes.USER_SHOULD_SETUP_ACCOUNT,
  ]);

  const isPrivateRouteAllowed = useMemo(
    () =>
      user === null
      || adContext === null
      || user?.is_tvsci_employee === null
      || adContext.cost_model === null
      || user?.is_tvsci_employee
      || adContext?.cost_model !== 'CPA'
      || isTenantAdmin
    ,[user, adContext]
  );

  useInterceptors();

  useIntercom({
    advertiser: currentAdvertiser,
  });

  const routes = createRoutes({
    key,
    theme,
    flags,
    isAuthenticated,
    canViewBusinessManager,
    canViewCampaignSetup,
    canUpdateCampaigns,
    canViewReports,
    isPrivateRouteAllowed,
    isFlagsLoading,
  });

  useEffect(() => {
    const { theme: prevTheme } = currentAdvertiser || {};
    if (prevTheme === theme) return;

    adContext.updateAdvertiser({ theme });
  }, [theme]);

  // TODO: this function and the following useEffect
  // TODO: should go in the AdvertiserContext Provider
  const handleUpdateLooker = uses_looker => {
    if (!adContext.uses_looker || adContext.uses_looker !== uses_looker) {
      return adContext.updateAdvertiser({ uses_looker });
    }
  };

  useEffect(() => {
    if (user) {
      handleUpdateLooker(user.uses_looker);
    }
  }, [user]);

  useEffect(() => {
    if (authState.isAuthenticated) {
      getSSOUser();
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    if (user?.email) {
      identify(user.id);
      setUserVars({
        displayName: `${user.first_name} ${user.last_name}`,
        email: user.email,
      });
    }
  }, [user]);

  useEffect(() => {
    if (
      user
      && !user.has_agreed_to_latest_terms
      && user?.url
      && org?.url
      && latestTenantTermAndCondition.url
      && !termsAndConditionsMatch
    ) {
      setDialog(termsAndConditionsDialog({
        onSubmit: async () => {
          try {
            await postUserAgreementTrigger({
              terms: latestTenantTermAndCondition.url,
              user: user.url,
              organization: org.url,
            });

            await getUser();

            setDialog(null);
          } catch (error) {
            console.error('error in saving user agreement ', error);
          }
        }
      }));
    }
  }, [user, org, latestTenantTermAndCondition, termsAndConditionsMatch]);

  useEffect(() => {
    const setupRoutes = [
      RoutePaths.HOME,
      RoutePaths.CREATE_ACCOUNT,
      RoutePaths.ACCOUNT_SETUP,
      RoutePaths.JOIN,
      RoutePaths.ACCEPT_INVITE
    ];

    if (!arePermissionsLoaded) return;
    if (
      setupRoutes.some(path =>
        matchPath(history.location.pathname, {
          path,
          exact: true,
          strict: false,
        }),
      )
    ) {
      return;
    }

    const isAvailableAdAccountsLoaded = advertisers !== null;

    if (isAvailableAdAccountsLoaded && shouldSetupAccount)
      return history.push('/account-setup');

    if (!isAvailableAdAccountsLoaded) return;

    const hasAdAccountsToAccess = advertisers?.length >= 1;
    if (hasAdAccountsToAccess && canAccessApplication) return;

    history.push('/member');
  }, [
    advertisers,
    history.location.pathname,
    shouldSetupAccount,
    arePermissionsLoaded,
  ]);

  return (
    <Switch>
      {routes.map(({ type: RouteType, ...routeProps }, index) => (
        <RouteType key={index} {...routeProps} />
      ))}
    </Switch>
  );
};

export default App;
