import { useCreateAdGroup, usePatchAdGroup } from '@apis/adGroups';
import { useGetAllBidStrategies } from '@apis/bidStrategy';
import {
  useGetCreativeLineItemTrigger,
  usePatchCreative,
  usePatchCreativeLineItem,
} from '@apis/creatives';
import { usePatchStaticDisplayCreative } from '@apis/displays';
import {
  useCreateStaticDisplayAdGroup,
  usePatchStaticDisplayAdGroup,
} from '@apis/staticDisplayAdGroups';
import { useWizardSection } from '@components/Wizard';
import { omit, pick } from 'lodash';
import { removeNullValues } from '../../utils';
import { pixelsToJSON, transformAdGroup } from '../utils';
import { useGetAllowedTargeting } from '@components/hooks/apis/allowedTargeting';
import { useGeneralNavigation } from '../../hooks/useGeneralNavigation';

export const useGroupSectionSubmit = ({
  isDisplay,
  campaign,
  form,
  sectionKey,
  adGroup,
  mutateAdGroupsOption,
  mutateStaticDisplayAdGroupsOption,
}) => {
  const { state, toCampaignDetails } = useGeneralNavigation();
  const { data: allowedTargeting } = useGetAllowedTargeting();
  const { data: bidStrategies } = useGetAllBidStrategies();
  const { trigger: updateLineItemCreative, isMutating: isPostMutating } =
    usePatchCreativeLineItem();

  const { trigger: updateAdGroup, isMutating: isPatchMutating } =
    usePatchAdGroup(mutateAdGroupsOption);
  const { trigger: createAdGroup } = useCreateAdGroup(mutateAdGroupsOption);
  const { trigger: updateCreative } = usePatchCreative();
  const { trigger: getCreativeLineItem } = useGetCreativeLineItemTrigger();

  const {
    trigger: updateStaticDisplayAdGroup,
    isMutating: isStaticDisplayPatchMutating,
  } = usePatchStaticDisplayAdGroup(mutateStaticDisplayAdGroupsOption);
  const { trigger: updateStaticDisplayCreative } =
    usePatchStaticDisplayCreative();
  const { trigger: createStaticDisplayAdGroup } =
    useCreateStaticDisplayAdGroup(mutateStaticDisplayAdGroupsOption);

  const { goToNext } = useWizardSection({
    key: sectionKey,
  });

  const handleSubmit = async () => {
    const values = form.getValues();
    const { creativesAdditionalData, ...rest } = transformAdGroup(values, {
      bidStrategies,
      campaign,
      adGroup,
      allowedTargeting,
    });

    const res = await (!adGroup?.temporary
      ? updateAdGroup({ id: adGroup.id, ...rest })
      : createAdGroup(rest));

    const lineItems = await getCreativeLineItem(res.id);

    await Promise.all(
      creativesAdditionalData.map(
        async ({ id, weighting, name, click_url, pixels }) => {
          const updatedData = pick(
            lineItems.find(({ creative }) => creative === id),
            ['id', 'lineitem', 'creative'],
          );
          await updateCreative(
            removeNullValues({
              name,
              id: updatedData.creative,
              pixels: pixelsToJSON(pixels),
              click_url,
            }),
          );

          return updateLineItemCreative(
            removeNullValues({
              id: updatedData.id,
              creative: updatedData.creative,
              lineitem: updatedData.lineitem,
              weighting,
            }),
          );
        },
      ),
    ).catch(e => console.log(e));

    state.singleEdit && campaign.active
      ? toCampaignDetails({ campaign })
      : goToNext({ checkDirty: false });
  };

  const handleStaticDisplaySubmit = async () => {
    const values = form.getValues();
    const { creativesAdditionalData, ...rest } = transformAdGroup(values, {
      bidStrategies,
      campaign,
      adGroup,
    });

    const res = await (!adGroup?.temporary
      ? updateStaticDisplayAdGroup({ id: adGroup.id, ...rest })
      : createStaticDisplayAdGroup(omit(rest, ['id'])));

    if (res.id) {
      await Promise.all(
        res.creatives.map(async staticDisplayCreativeId => {
          const additionalData = creativesAdditionalData.filter(
            ({ id }) => id === staticDisplayCreativeId,
          )[0];

          await updateStaticDisplayCreative(
            removeNullValues({
              ...additionalData,
              id: staticDisplayCreativeId,
            }),
          );
        }),
      ).catch(e => console.log(e));

      state.singleEdit && campaign.active
        ? toCampaignDetails({ campaign })
        : goToNext({ checkDirty: false });
    }
  };

  return {
    submit: isDisplay ? handleStaticDisplaySubmit : handleSubmit,
    mutating:
      isPostMutating || isPatchMutating || isStaticDisplayPatchMutating,
  };
};
