import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { RoutePaths } from '@constants/routes';
import { View, ViewComponents } from './constants';
import AppHeader from '../../AppHeader';

const CreativeIndexPage = ({ history }) => {
  const [view, setView] = useState(View.VIEW_CREATIVES);
  const { adGroupId, campaignId } = useParams();

  const ViewComponent = useMemo(() => ViewComponents[view], [view]);

  const handleActionChange = (newAction) => {
    setView(newAction);
  };

  return (
    <AppHeader history={history}>
      <Box m={5} mb={2}>
        <Typography>
          <strong>
            <Link
              sx={{
                textDecoration: 'none',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: '700',
              }}
              to={RoutePaths.CAMPAIGNS}
            >
              Campaigns
            </Link>
          </strong>
          &gt;
          <strong>
            <Link
              sx={{
                textDecoration: 'none',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: '700',
              }}
              to={generatePath(RoutePaths.OLD_CAMPAIGNS_EDIT, {
                campaignId,
                adGroupId,
              })}
            >
              Ad Groups
            </Link>
          </strong>
          &gt;
          Creative
        </Typography>
      </Box>

      <ViewComponent onActionChange={handleActionChange} />
    </AppHeader>
  );
};

CreativeIndexPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CreativeIndexPage;
