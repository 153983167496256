import { RoutePaths } from '@constants/routes';
import { getPrimaryOrgId } from '@helpers/getPrimaryOrgId';
import { generatePath, useHistory } from 'react-router';
import { useAdvertisers } from './advertisers';
import { useQuery } from './query';
import { Flags } from '@constants/flags';
import { useFlags } from './flags';

export const useWizardRedirect = () => {
  const urlQuery = useQuery();
  const history = useHistory();
  const { currentAdvertiser } = useAdvertisers();
  const { flags } = useFlags();

  const handleSetQuery = () => {
    urlQuery.set('org', getPrimaryOrgId(currentAdvertiser.primary_org));
    urlQuery.set('adAccount', currentAdvertiser?.id);
  };

  const handleReplicateQuery = (additionalQueryParameters = {}) => {
    const newQuery = new URLSearchParams(urlQuery.toString());

    Object.keys(additionalQueryParameters).forEach(key => {
      newQuery.set(key, additionalQueryParameters[key]);
    });
    return newQuery;
  };

  const handleEditCampaign = ({ campaignId, query }) => {
    handleSetQuery();

    history.push({
      pathname: generatePath(RoutePaths.CAMPAIGN_REWORK, { campaignId }),
      search: handleReplicateQuery(query).toString(),
    });
  };

  const handleEditGroup = ({ campaignId, groupId, query }) => {
    handleSetQuery();

    history.push({
      pathname: generatePath(RoutePaths.CAMPAIGN_REWORK, {
        campaignId,
        sectionId: groupId,
      }),
      search: handleReplicateQuery(query).toString(),
    });
  };

  const handleNewGroup = ({ campaignId, query }) => {
    handleSetQuery();

    history.push({
      pathname: generatePath(RoutePaths.CAMPAIGN_REWORK, {
        campaignId,
        sectionId: 'new',
      }),
      search: handleReplicateQuery(query).toString(),
    });
  };

  return {
    isWizardEnabled: flags[Flags.USER_GETS_VERTICAL_CAMPAIGN],
    newGroup: handleNewGroup,
    editCampaign: handleEditCampaign,
    editGroup: handleEditGroup,
  };
};
