import React, { useReducer, useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';

import { useAuth } from '@hooks/auth';
import { useAPI } from '@hooks/api';

const AdvertisersContext = React.createContext(null);

const advertisersReducer = (state, action) => {
  switch (action.type) {
    case 'set': {
      return {
        ...state,
        advertisers: action.payload,
      };
    }
    case 'append': {
      return {
        ...state,
        advertisers: state.advertisers ? [ ...state.advertisers, ...action.payload] : action.payload,
      };
    }
    case 'add':
      return {
        ...state,
        advertisers: sortBy(
          [...state.advertisers, ...action.payload],
          ['org_name', 'ad_account_name']
        ),
      };
    case 'replace':
      return {
        ...state,
        advertisers: state.advertisers.map(ad => ad.id == action.payload.id ? action.payload : ad),
      }
    default:
      return state;
  }
};

export const AdvertisersContextProvider = ({ children }) => {
  const { authState } = useAuth();
  const { useGetPaginated } = useAPI();
  const [fetchingAdvertisers, setFetchingAdvertisers] = useState(false);
  const [{ advertisers }, dispatchAdvertisers] = useReducer(
    advertisersReducer,
    {
      advertisers: null,
    }
  );

  const getAdvertisers = async () => {
    setFetchingAdvertisers(true);

    await useGetPaginated('/advertisers', (results, isFirstPage) => {
      if (isFirstPage) {
        dispatchAdvertisers({type: 'set', payload: results });
      } else {
        dispatchAdvertisers({ type: 'append', payload: results });
      }
    });
    setFetchingAdvertisers(false);
  };

  useEffect(() => {
    if (!authState.isAuthenticated) {
      return;
    }

    getAdvertisers();
  }, [authState.isAuthenticated]);

  return (
    <AdvertisersContext.Provider
      value={{
        advertisers,
        fetchingAdvertisers,
        dispatchAdvertisers,
      }}
    >
      {children}
    </AdvertisersContext.Provider>
  );
};

AdvertisersContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdvertisersContext;
