import { Stack, styled } from '@mui/material';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { Variant, iconColors, iconSize, textColors } from './constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareInfo } from '@fortawesome/pro-solid-svg-icons';
import { get } from 'lodash';

const Alert = styled(Stack)(({ theme, type, variant }) => {
  const AlertColor = {
    [Variant.filled]: {
      success: theme.palette.green[10],
      info: theme.palette.blue[10],
      error: theme.palette.red[5],
      warning: theme.palette.tip.light,
      caption: theme.palette.grey[4],
    },
    [Variant.outlined]: {
      caption: 'transparent',
      success: 'transparent',
      info: 'transparent',
      error: 'transparent',
    },
  };

  return `
    padding: ${variant === 'outlined' ? 0 : theme.spacing(1.5, 2.5)};
    border-radius: ${theme.spacing(0.55)};
    gap: ${theme.spacing(1.5)};
    align-items: center;
    background-color: ${AlertColor[variant][type]};
  `;
});

const IconStyled = styled(FontAwesomeIcon, {
  shouldForwardProp: prop =>
    prop !== 'color' && prop !== 'type' && prop !== 'variant',
})(({ theme, color, type, variant }) => ({
  paddingTop: theme.spacing(0.25),
  width: theme.spacing(iconSize[variant][type]),
  height: theme.spacing(iconSize[variant][type]),
  color: get(theme.palette, color),
}));

const Info = ({
  type = 'success',
  variant = 'filled',
  icon,
  children,
  ...props
}) => {
  const actualIcon = useMemo(() => {
    if (icon) {
      return icon;
    }

    const iconSx = {
      [Variant.filled]: {
        color: iconColors[variant][type],
      },
      [Variant.outlined]: {
        color: iconColors[variant][type],
      },
    };

    const sx = iconSx[variant];

    return (
      <IconStyled
        icon={faSquareInfo}
        variant={variant}
        type={type}
        color={sx.color}
      />
    );
  }, [icon, variant, iconColors]);
  return (
    <Alert
      {...props}
      direction="row"
      color={textColors[variant][type]}
      variant={variant}
      type={type}
    >
      {actualIcon}
      {children}
    </Alert>
  );
};

Info.propTypes = {
  ...Stack.propTypes,
  variant: PropTypes.oneOf(Object.keys(Variant)),
  icon: PropTypes.node,
};

export default Info;
