import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { sectionProps } from '@components/Wizard';
import { SWRConfig } from 'swr';
import { withCamelCase } from '@swr/withCamelCase';
import { useReviewSection } from './hooks/useReviewSection';

const ReviewAndLaunchInfoPane = lazy(
  () => import('./panes/ReviewAndLaunchInfoPane/ReviewAndLaunchInfoPane'),
);

const ReviewSection = ({ adGroups, campaignId, retargetingAdGroups }) => {
  const { adGroups: camelCasedGroups } = useReviewSection({
    groups: adGroups,
  });
  return (
    <ReviewAndLaunchInfoPane
      key="info"
      adGroups={camelCasedGroups}
      campaignId={campaignId}
      retargetingAdGroups={retargetingAdGroups}
    />
  );
};

const ReviewSectionWrapper = props => {
  return (
    <SWRConfig
      value={parent => ({
        use: [...parent.use, withCamelCase],
      })}
    >
      <ReviewSection {...props} />
    </SWRConfig>
  );
};

ReviewSection.propTypes = {
  ...sectionProps,
  adGroups: PropTypes.arrayOf(PropTypes.object),
  retargetingAdGroups: PropTypes.arrayOf(PropTypes.object),
};

ReviewSectionWrapper.propTypes = {
  ...ReviewSection,
};

export default ReviewSectionWrapper;
