import { useContext } from 'react';
import { useCopy, useFlags } from '@hooks';
import {
  Copies as AdvancedDeliveryOptionsFormCopies,
} from '../../AdvancedDeliveryOptionsForm/constants';
import { Copies } from '../constants';
import { getMinDate } from '@components/WizardContainers/validations';
import { Themes } from '@constants/themes';
import AdvertiserContext from '@components/AdvertiserContext';

export const useCampaignForm = ({
  campaignStaticDisplayAdGroups,
  endDate,
  isIncremental,
  startDate,
}) => {
  const { theme } = useContext(AdvertiserContext);

  const Copy = useCopy(Copies);

  const { flags, Flags } = useFlags();

  const AdvancedDeliveryOptionsFormCopy = useCopy(
    AdvancedDeliveryOptionsFormCopies,
  );

  const dragsAllowed = flags[Flags.USER_GETS_VERTICAL_CAMPAIGN_DRAGS]
    && theme !== Themes.NBCU;

  const minEndDate = getMinDate(startDate, endDate);

  const campaignHasSavedDrag =
    campaignStaticDisplayAdGroups.length > 0 &&
    !campaignStaticDisplayAdGroups[0].temporary;

  const dragToggleDisabled = campaignHasSavedDrag || isIncremental;

  const dragToggleText = isIncremental ?
    'Display retargeting is not available for incrementality tests.' :
    Copy.dragToggleText;

  const dragTooltip = campaignHasSavedDrag ?
    'Retarget exposed audience with display ads. This cannot be toggled off \
    once the display ad group is saved.' :
    Copy.dragToggleText;

  return {
    AdvancedDeliveryOptionsFormCopy,
    Copy,
    dragsAllowed,
    dragToggleDisabled,
    dragToggleText,
    dragTooltip,
    minEndDate,
  };
};
