import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { uniqueId } from 'lodash';

export const useUniqueLocationKey = () => {
  const { state } = useLocation();
  const { key } = state || {};

  return useMemo(() => key ?? uniqueId(), [key]);
};
