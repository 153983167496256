import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import { WizardSection } from '@v2/components/wizard/WizardSection';
import { useGetCampaign } from '../../hooks/apis/campaigns';
import { useCampaignAdGroupsPage } from '../../hooks/apis/adGroups';

const ReviewAndLaunchInfoPane = lazy(
  () => import('./ReviewAndLaunchInfoPane/ReviewAndLaunchInfoPane'),
);

const ReviewAndLaunchSection = ({
  sectionKey,
  campaignId,
  ...sectionProps
}) => {
  const { campaign } = useGetCampaign(campaignId, false);
  const { items: campaignAdGroups } = useCampaignAdGroupsPage(campaignId);

  return (
    <WizardSection
      sectionKey={sectionKey}
      disabled={!campaign?.id || !campaignAdGroups?.length}
      title="Review & Launch"
      icon="faRocket"
      panes={[
        <ReviewAndLaunchInfoPane
          key="info"
          campaignId={campaignId}
          {...sectionProps}
        />,
      ]}
    />
  );
};

ReviewAndLaunchSection.propTypes = {
  sectionKey: PropTypes.string,
  campaignId: PropTypes.number,
};

export default ReviewAndLaunchSection;
