import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';

import { CampaignForm } from '@v2/components/forms/CampaignForm';

import { useCopy, useCurrentSession } from '@hooks';
import { getRecommendedCampaignObjectForAdvertiser } from './utils';

import { useWizardContext } from '@components/Wizard';
import { SCROLL_DELAY } from '../../../constants';
import { useScrollTo } from '../../../hooks/useScrollTo';
import { labels } from '../../constants';
import { Copies, objectivesOptions } from './constants';

function CampaignInfoPane({
  sectionKey,
  campaignId,
  onRetargetDisplayToggleChange,
  campaignStaticDisplayAdGroups,
  onSubmit,
}) {
  const Copy = useCopy(Copies);

  const ref = useScrollTo(SCROLL_DELAY);
  const { currentAdvertiser } = useCurrentSession();
  const {
    navigation: { goTo },
  } = useWizardContext();

  const recommendedCampaignObjective = useMemo(
    () => getRecommendedCampaignObjectForAdvertiser(currentAdvertiser),
    [currentAdvertiser],
  );

  const disabledObjectiveOptions = useMemo(() => {
    return {
      appInstalls: false,
      websiteSales: false,
      websiteVisits: false,
      audienceReach: false,
      audienceFrequency: false,
    };
  }, []);

  const handleOpenAdvancedCampaignDeliveryOptions = useCallback(() => {
    goTo({
      campaignId,
      sectionId: sectionKey,
      paneId: labels.objective.value,
    });
  }, [goTo, campaignId, sectionKey]);

  return (
    <CampaignForm
      ref={ref}
      onRetargetDisplayToggleChange={onRetargetDisplayToggleChange}
      campaignStaticDisplayAdGroups={campaignStaticDisplayAdGroups}
      onSubmit={onSubmit}
      onAdvancedCampaignDeliveryOptionsClick={
        handleOpenAdvancedCampaignDeliveryOptions
      }
      ObjectiveOptionArgs={{
        name: 'campaign-objective-tracking-on',
        headerTitle: Copy.campaignObjectiveHeader,
        trackingIsSetUp: true,
        campaignOptions: objectivesOptions({
          recommendedCampaignObjective,
          disabledObjectiveOptions,
          copies: Copy,
        }),
      }}
    />
  );
}

CampaignInfoPane.propTypes = {
  onRetargetDisplayToggleChange: PropTypes.func,
  campaignStaticDisplayAdGroups: PropTypes.array,
  campaignId: PropTypes.number,
  paneKey: PropTypes.string.isRequired,
  sectionKey: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default memo(CampaignInfoPane);
