import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Button, ButtonBase, FormControlLabel, Grid, MenuItem, Switch, TextField, Typography } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';

import { convertToTime } from './util';
import freqCapIcon from '../images/icon-config-freqcaps.png'
import { BidStrategy } from './BidStrategy';

const PREFIX = 'AdGroupBasics';

const classes = {
  name: `${PREFIX}-name`,
  cap: `${PREFIX}-cap`,
  freqCap: `${PREFIX}-freqCap`,
  freqCapsBtn: `${PREFIX}-freqCapsBtn`,
  freqCapWrap: `${PREFIX}-freqCapWrap`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.name}`]: {
    marginTop: 6,
  },

  [`& .${classes.cap}`]: {
    color: '#181c20',
    fontSize: '.925rem',
    fontWeight: 400,
    lineHeight: '1.4rem',
  },

  [`& .${classes.freqCap}`]: {
    color: '#5c6b73',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.freqCapsBtn}`]: {
    width: '100%',
    textAlign: 'left',
  },

  [`& .${classes.freqCapWrap}`]: {
    border: '1px solid rgb(224, 224, 224)',
    padding: theme.spacing(3),
    borderRadius: 10,
    textAlign: 'left',
    width: '100%',
  }
}));

const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const AdGroupBasics = ({
  isError,
  isStartBefore,
  isEndBefore,
  name,
  deliveryTime,
  endDate,
  freqCaps,
  startDate,
  setIsFreqCaps,
  currentDayPartsCopy,
  dayParts,
  setIsDayParting,
  setName,
  setDeliveryTime,
  handleBidStategyChange,
  isInternalUser,
  adGroup,
  showBidStrategy,
  bidStrategy,
  bidStrategyRef,
  noEndDate,
  handleNoEndDateChange,
  handleUpdateStartDate,
  handleUpdateEndDate,
  handleUpdateStartTime,
  handleUpdateEndTime,
}) => (
  <StyledGrid container alignItems="flex-start" justifyContent="space-between" spacing={3}>
    <Grid item container direction="column" spacing={2} xs={5}>
      <Grid item>
        <TextField
          error={isError && (!name || name === '')}
          className={classes.name}
          color="secondary"
          fullWidth
          id="ad-group-name"
          label="Name"
          placeholder="Enter a name for your ad group"
          variant="outlined"
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </Grid>

      <Grid item container justifyContent="flex-end">
        <FormControlLabel
          control={
            <Switch
              checked={noEndDate}
              onChange={handleNoEndDateChange}
              name="noEndDate"
              color="secondary"
              size="small"
            />
          }
          label="No end date"
        />
      </Grid>

      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <DatePicker
            color="secondary"
            disableToolbar
            disabled={isStartBefore}
            inputFormat="MM/dd/yyyy"
            id="start-date"
            inputVariant="outlined"
            label="Start Date"
            data-testid="start-date-picker"
            margin="normal"
            onChange={handleUpdateStartDate}
            value={startDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            fullWidth
            timezone="America/New_York"
          />
        </Grid>

        <Grid item xs={6}>
          <DatePicker
            color="secondary"
            disabled={isEndBefore || noEndDate}
            disableToolbar
            inputFormat="MM/dd/yyyy"
            fullWidth
            id="end-date"
            inputVariant="outlined"
            label="End Date"
            data-testid="end-date-picker"
            margin="normal"
            onChange={handleUpdateEndDate}
            value={noEndDate ? null : endDate}
            variant="inline"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            timezone="America/New_York"
          />
        </Grid>
        <Grid item xs={6}>
          <TimePicker
            data-testid="start-time-picker"
            className={classes.datePicker}
            margin="normal"
            inputVariant="outlined"
            label="Start Time (ET)"
            id="time-picker"
            value={startDate}
            onChange={handleUpdateStartTime}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TimePicker
            data-testid="end-time-picker"
            className={classes.datePicker}
            disabled={noEndDate}
            margin="normal"
            inputVariant="outlined"
            label="End Time (ET)"
            id="time-picker-end"
            value={noEndDate ? null : endDate}
            onChange={handleUpdateEndTime}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item>
        <Box width="100%">
          <TextField
            select
            color="secondary"
            fullWidth
            label="Delivery Timezone"
            variant="outlined"
            value={deliveryTime}
            onChange={evt => setDeliveryTime(evt.target.value)}
          >
            <MenuItem value="est">
              Eastern Time (GMT-05:00)
            </MenuItem>

            <MenuItem value="user">
              Viewers&apos; Local Time
            </MenuItem>
          </TextField>
        </Box>
      </Grid>

      <Grid item>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              mb={1}
            >
              <Button
                className="freq-cap-btn"
                onClick={() => setIsFreqCaps(true)}
                startIcon={<img src={freqCapIcon} alt="Configure frequency cap icon" width={17} />}
              >
                <Typography className={classes.freqCap}>
                  Configure frequency cap
                </Typography>
              </Button>

              {freqCaps && freqCaps.length > 0 && (
                <ButtonBase
                  disableRipple
                  onClick={() => setIsFreqCaps(true)}
                  className={classes.freqCapsBtn}
                >
                  <Box my={1} className={classes.freqCapWrap}>
                    {freqCaps.map(f => (
                      <Typography key={f.fctype} className={classes.cap}>
                        {`${f.impressions} ads every ${f.duration} ${f.fctype.toLowerCase()}(s)`}
                      </Typography>
                    ))}
                  </Box>
                </ButtonBase>
              )}
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Button
                className="freq-cap-btn"
                onClick={() => setIsDayParting(true)}
                startIcon={<img src={freqCapIcon} alt="Configure frequency cap icon" width={17} />}
              >
                <Typography className={classes.freqCap}>
                  {currentDayPartsCopy}
                </Typography>
              </Button>

              {dayParts.length > 0 &&
                <ButtonBase
                  className={classes.freqCapsBtn}
                  p={0}
                  onClick={() => setIsDayParting(true)}
                >
                  <Box my={1} className={classes.freqCapWrap}>
                    {dayParts.map((part, index) =>
                      <Typography key={index} className={classes.cap}>
                        {days.filter(d => part.days[d.toLowerCase()].selected).join(', ')}

                        {` | `}

                        {`${convertToTime(part.time[0], -12, 12)}-
                        ${convertToTime(part.time[1], -12, 12)}`}
                      </Typography>)}
                  </Box>
                </ButtonBase>}
            </Box>
          </Box>


        </Box>
      </Grid>
    </Grid>

    {showBidStrategy && (
      <Grid item xs={5}>
        <BidStrategy
          ref={bidStrategyRef}
          isInternalUser={isInternalUser}
          adGroup={adGroup}
          handleBidStategyChange={handleBidStategyChange}
          bidStrategy={bidStrategy}
        />
      </Grid>
    )}
  </StyledGrid>
);

AdGroupBasics.propTypes = {
  isError: PropTypes.bool,
  isNew: PropTypes.bool,
  isStartBefore: PropTypes.bool,
  isEndBefore: PropTypes.bool,
  name: PropTypes.string,
  freqCaps: PropTypes.array,
  dayParts: PropTypes.array,
  currentDayPartsCopy: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  setName: PropTypes.func,
  setIsFreqCaps: PropTypes.func,
  setIsDayParting: PropTypes.func,
  setEndDate: PropTypes.func,
  setStartDate: PropTypes.func,
  deliveryTime: PropTypes.string,
  setDeliveryTime: PropTypes.func,
  isInternalUser: PropTypes.bool,
  adGroup: PropTypes.object,
  handleBidStategyChange: PropTypes.func.isRequired,
  showBidStrategy: PropTypes.bool,
  bidStrategy: PropTypes.object,
  bidStrategyRef: PropTypes.object,
  noEndDate: PropTypes.bool,
  setNoEndDate: PropTypes.func,
  handleNoEndDateChange: PropTypes.func.isRequired,
  handleUpdateStartDate: PropTypes.func.isRequired,
  handleUpdateEndDate: PropTypes.func.isRequired,
  handleUpdateStartTime: PropTypes.func.isRequired,
  handleUpdateEndTime: PropTypes.func.isRequired
};

export default AdGroupBasics;
