import { useFormContext } from 'react-hook-form';
import { fields } from '../constants';
import { useMemo, useState } from 'react';
import { useGetAllowedTargeting } from '../../../../../components/hooks/apis/allowedTargeting';
import { getTargetingValue } from '../utils';
import { useDomain } from '@components/hooks/domain';

export const useInventory = () => {
  const { setValue, control, watch } = useFormContext();
  const { data } = useGetAllowedTargeting();
  const domain = useDomain();
  const [isCustomInventoryExpanded, setIsCustomInventoryExpanded] =
    useState(false);

  const [customInventoryItems, advancedInventory] = watch([
    fields.advancedCustomInventory.path,
    fields.advancedInventory.path,
  ]);

  const actualAdvancedInventory = useMemo(() => {
    const { os = {}, device = {} } = advancedInventory ?? {};

    return [
      ...os.ids.map(key => ({
        ...getTargetingValue(key, data),
        included: !os.blacklist,
      })),
      ...device.ids.map(key => ({
        ...getTargetingValue(key, data),
        included: !device.blacklist,
      })),
    ];
  }, [advancedInventory]);

  return {
    customInventoryItems,
    setValue,
    control,
    domain,
    isCustomInventoryExpanded,
    setIsCustomInventoryExpanded,
    inventory: actualAdvancedInventory,
  };
};
