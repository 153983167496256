import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import { generatePath, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrganizationRoles, RoutePaths, Scopes, Themes } from '../../../constants';
import AddIcon from '@mui/icons-material/Add';

import AdvertiserContext from '../../AdvertiserContext';

import {
  useQuery,
  useFlags,
  usePermissions,
  useAdvertisers,
  useUser,
  useOrg,
} from '../../hooks';
import { getPrimaryOrgId } from '../../../helpers';

const PREFIX = 'CreateCampaignButton';

const classes = {
  create: `${PREFIX}-create`,
};

const StyledGrid = styled(Grid, {
  shouldForwardProp: prop => !['appTheme', 'themeVersion'].includes(prop),
})(({ appTheme, themeVersion }) => ({
  [`& .${classes.create}`]: {
    fontSize: '1rem',
    lineHeight: 'normal',
    transition: 'background-color 300ms',
    padding: '8px 24px',
    ...createButtonStyles[appTheme][themeVersion],
  },
}));

const createButtonStyles = {
  [Themes.DEFAULT]: {
    v1: {
      backgroundColor: '#0A405E',
      color: '#C2F2E2',

      '& .addIcon': {
        fill: '#57D9AD',
      },

      '&.disabledButton': {
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        boxShadow: 'none',
      },
    },
    v2: {
      backgroundColor: '#29CC95',
      color: 'black',

      '&:hover': {
        backgroundColor: `#0FBF84`,
        cursor: 'unset',
      },

      '& .addIcon': {
        fill: 'black',
      },

      '&.disabledButton': {
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        boxShadow: 'none',
      },
    },
  },
  [Themes.NBCU]: {
    v1: {
      backgroundColor: '#faca13',
      color: '#000000',
      boxShadow: 'none',

      '& .addIcon': {
        fill: '#000000',
      },

      '&.disabledButton': {
        backgroundColor: '#faca1345',
      },
    },
    v2: {
      backgroundColor: '#faca13',
      color: '#000000',
      boxShadow: 'none',

      '& .addIcon': {
        fill: '#000000',
      },

      '&.disabledButton': {
        backgroundColor: '#faca1345',
      },
    },
  },
};

const CreateCampaignButton = () => {
  const history = useHistory();
  const urlQuery = useQuery();
  const { user } = useUser();
  const adContext = useContext(AdvertiserContext);
  const { currentAdvertiser } = useAdvertisers();
  const { enqueueSnackbar } = useSnackbar();
  const { hasPermission } = usePermissions();
  const { flags, Flags } = useFlags();
  const isVerticalCampaignFlowEnabled = flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];
  const themeVersion = isVerticalCampaignFlowEnabled ? 'v2' : 'v1';
  const { org } = useOrg();

  const isTenantAdmin = useMemo(
    () => org?.organization_role === OrganizationRoles.TENANT_ADMIN,
    [org?.organization_role]
  );

  const canCreateCampaign =
    (hasPermission([Scopes.CAN_CREATE_CAMPAIGN]) &&
      currentAdvertiser?.cost_model === 'CPM') ||
    user?.is_tvsci_employee ||
    isTenantAdmin;

  const handleCreate = () => {
    if (!canCreateCampaign) {
      enqueueSnackbar(
        'You are not allowed to Create Campaigns as an Analyst',
        {
          variant: 'warning',
        },
      );

      return;
    }

    if (currentAdvertiser?.id) {
      return openNewCampaign();
    }
  };

  const openNewCampaign = () => {
    if (isVerticalCampaignFlowEnabled) {
      urlQuery.set('org', getPrimaryOrgId(currentAdvertiser.primary_org));
      urlQuery.set('adAccount', currentAdvertiser?.id);
      urlQuery.delete('sectionKey');

      return history.push({
        pathname: generatePath(RoutePaths.WIZARD_SELECTOR),
        search: urlQuery.toString(),
        state: { key: uniqueId() },
      });
    }

    return history.push(RoutePaths.OLD_CAMPAIGN_SETUP);
  };

  return (
    <StyledGrid item appTheme={adContext.theme} themeVersion={themeVersion}>
      <Button
        id="create-campaign"
        className={clsx('Nav-create-campaign', classes.create, {
          disabledButton: !canCreateCampaign,
        })}
        disableTouchRipple={canCreateCampaign}
        color="primary"
        disableElevation
        disableFocusRipple
        disableRipple
        onClick={handleCreate}
        startIcon={<AddIcon className="addIcon" />}
        variant="contained"
      >
        Create Campaign
      </Button>
    </StyledGrid>
  );
};

export default CreateCampaignButton;
