import { yupResolver } from '@hookform/resolvers/yup';
import { defaults } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  fields,
  formSchema,
  defaultValues as initialValues,
} from '../formConfig';
import { transformAdGroupToFields } from '../utils';

export const useGroupSectionForm = ({
  adGroup,
  bidStrategies,
  bundles,
  library,
  targeting,
  audiences,
  campaign,
}) => {
  const values = useMemo(
    () =>
      defaults(
        transformAdGroupToFields(adGroup, {
          customInventory: bundles,
          library,
          audiences,
          allowedTargeting: targeting,
          campaign,
        }),
        initialValues(),
      ),
    [adGroup, campaign, library, targeting, audiences, bundles],
  );

  const form = useForm({
    resolver: yupResolver(formSchema({ campaign, bidStrategies })),
    reValidateMode: 'all',
    mode: 'all',
    values,
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const { watch, trigger } = form;

  const [inventoryOption] = watch([fields.inventoryOption.path]);

  useEffect(() => {
    if (inventoryOption) {
      trigger(fields.advancedCustomInventory.path);
    }
  }, [trigger, inventoryOption]);

  return {
    form,
  };
};
