import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Container, Grid, Stack, Paper, Tabs, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { EmbedLookerChart } from '../../EmbedLookerChart';
import AppHeader from '../../AppHeader';
import AdvertiserContext from '../../AdvertiserContext';
import { useOrg, useUser } from "@hooks/index";
import { OrganizationRoles, Themes } from '@constants/index';
import { PathToPurchase } from '@components/containers/ReportsPage/PathToPurchase';

const PREFIX = 'ReportsPage';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  dashboard: `${PREFIX}-dashboard`,
  select: `${PREFIX}-select`,
  tabs: `${PREFIX}-tabs`,
};

const StyledAppHeader = styled(AppHeader)(({ theme }) => ({
  [`& .${classes.container}`]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(4),
    maxWidth: 'none',
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(5),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: 1200,
  },

  [`& .${classes.dashboard}`]: {
    '& > iframe': {
      width: '100%',
      height: 1100,
    }
  },

  [`& .${classes.select}`]: {
    width: 200,
  },

  [`& .${classes.tabs}`]: {
    minWidth: 275,
    margin: '0 20px',

    ['& .MuiTypography-root']: {
      fontSize: '1.25rem',
    },

    ['&.Mui-selected .MuiTypography-root']: {
      fontWeight: 700,
    }
  },
}));

const EmbedLookerChartStyled = styled(EmbedLookerChart)(() => ({
  // TODO: Dynamically stretch iframe height to fit content
  height: 1100,
}));

const CampaignPerformanceDashboard = {
  LOOKER_V1: '',
  LOOKER_V2: 'CAMPAIGN_PERFORMANCE',
};

const IncrementalityDashboards = {
  [Themes.DEFAULT]: 'INCREMENTALITY',
  [Themes.NBCU]: 'NBCU_INCREMENTALITY',
};

const ReportsPage = props => {
  const { user } = useUser();
  const { org } = useOrg();
  const adContext = useContext(AdvertiserContext);

  const [tab, setTab] = useState('campaignPerformance');

  const showLookerDashboard = useMemo(
    () => adContext?.uses_looker && adContext?.looker_validated,
    [adContext?.uses_looker, adContext?.looker_validated],
  );

  const campaignPerformanceDashboard = useMemo(() => {
    if (adContext.theme === Themes.NBCU) {
      return CampaignPerformanceDashboard.LOOKER_V1;
    }

    if (adContext?.cost_model === 'CPA') {
      return CampaignPerformanceDashboard.LOOKER_V2;
    }

    if (adContext?.cost_model === 'CPM' && showLookerDashboard) {
      return CampaignPerformanceDashboard.LOOKER_V2;
    }

    return CampaignPerformanceDashboard.LOOKER_V1;
  }, [adContext.theme, adContext?.cost_model, showLookerDashboard]);

  const incrementalityDashboard = useMemo(
    () => IncrementalityDashboards[adContext.theme],
    [adContext.theme]
  );

  const tabs = useMemo(() => {
    const tabsList = [];

    if (adContext.theme === Themes.NBCU && adContext?.cost_model === 'CPM') {
      tabsList.push({
        id: 'campaignDeliveryNBCU',
        title: 'Campaign Delivery',
        content: () => <EmbedLookerChartStyled dashboard="NBCU_CAMPAIGN_DELIVERY" />
      });

      tabsList.push({
        id: 'campaignPerformanceNBCU',
        title: 'Campaign Performance',
        content: () => <EmbedLookerChartStyled dashboard="NBCU_CAMPAIGN_PERFORMANCE" />
      });

      if (adContext.path_to_purchase_validated) {
        tabsList.push({
          id: 'pathToPurchase',
          title: 'Path to Purchase',
          content: () => showLookerDashboard ? <PathToPurchase /> : <EmbedLookerChartStyled dashboard={adContext.has_incrementality ? incrementalityDashboard : 'DATA_TABLE'} />
        });
      }
    }

    if (adContext.theme === Themes.NBCU && adContext?.cost_model === 'CPA') {
      tabsList.push({
        id: org?.organization_role === OrganizationRoles.TENANT_ADMIN ? 'cpaInternal' : 'cpaExternal',
        title: 'Campaign Performance',
        content: () => <EmbedLookerChartStyled dashboard={org?.organization_role === OrganizationRoles.TENANT_ADMIN ? 'CPA_INTERNAL_NBCU' : 'CPA_EXTERNAL_NBCU'} />
      });
    }

    if (adContext.theme === Themes.DEFAULT) {
      if (adContext?.cost_model === 'CPM' && showLookerDashboard) {
        tabsList.push({
          id: 'campaignDelivery',
          title: 'Campaign Delivery',
          content: () => <EmbedLookerChartStyled dashboard="CAMPAIGN_DELIVERY" />
        });
      }

      tabsList.push({
        id: 'campaignPerformance',
        title: 'Campaign Performance',
        content: () => <EmbedLookerChartStyled dashboard={campaignPerformanceDashboard} />
      });

      if (adContext.path_to_purchase_validated) {
        tabsList.push({
          id: 'pathToPurchase',
          title: 'Path to Purchase',
          content: () => showLookerDashboard ? <PathToPurchase /> : <EmbedLookerChartStyled dashboard={adContext.has_incrementality ? incrementalityDashboard : 'DATA_TABLE'} />
        });
      }

      if (adContext?.cost_model === 'CPM' && showLookerDashboard) {
        tabsList.push({
          id: 'inventoryAndGeo',
          title: 'Inventory & Geo',
          content: () => <EmbedLookerChartStyled dashboard="INVENTORY_GEO" />
        });
      }

      if (adContext.has_incrementality) {
        tabsList.push({
          id: 'campaignIncrementality',
          title: 'Campaign Incrementality',
          content: () => <EmbedLookerChartStyled dashboard={incrementalityDashboard} />
        });
      }

      if (user?.is_tvsci_employee) {
        tabsList.push({
          id: 'beta',
          title: 'Beta',
          content: () => (
            <Stack direction="column" spacing={2}>
              <Typography variant="body2">
                This screen is displaying reports that are tested internally while being considered for wider release. Please share with customers, and collect their feedback.
              </Typography>

              <EmbedLookerChartStyled dashboard="INTERNAL_EXPERIMENTAL" />
            </Stack>
          )
        });
      }

      if (adContext?.has_sequential_events) {
        tabsList.push({
          id: 'postConversions',
          title: 'Post Conversions',
          content: () => <EmbedLookerChartStyled dashboard="SEQUENTIAL_EVENTS" />
        });
      }
    }

    return tabsList;
  }, [
    adContext,
    showLookerDashboard,
    incrementalityDashboard,
    campaignPerformanceDashboard,
    user,
  ]);

  const { content: TabContent } = useMemo(
    () => tabs.find(({ id }) => id === tab) || {},
    [tabs, tab]
  );

  useEffect(() => {
    const id = tabs.length > 0 ? tabs[0].id : null;

    setTab(id);
  }, [adContext.id, tabs])

  return (
    <StyledAppHeader history={props.history}>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              className={clsx(classes.paper, 'background-img-inline-example')}
            >
              {adContext && (
                <Box my={4}>
                  <Tabs
                    value={tab}
                    onChange={(e, v) => setTab(v)}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {tabs.map(tab => (
                      <Tab
                        disableRipple
                        className={classes.tabs}
                        key={tab.id}
                        value={tab.id}
                        label={
                          <Typography>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {tab.title}
                            </Box>
                          </Typography>
                        }
                      />
                    ))}
                  </Tabs>
                </Box>
              )}

              {TabContent && <TabContent />}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </StyledAppHeader>
  );
};

ReportsPage.propTypes = {
  history: PropTypes.object,
};

export default ReportsPage;
