import { flatMap, intersection, keys } from 'lodash';
import { useSnackbar } from 'notistack';

import { additional_error_keys } from './constants';

const getMessage = data => Object.values(data);

export const useNotification = useSWRNext => (key, fetcher, config) => {
  const { enqueueSnackbar } = useSnackbar();
  const modifiedFetcher = async (...args) => {
    try {
      return await fetcher(...args);
    } catch (e) {
      const { response } = e;

      if (
        !intersection(
          [
            'error',
            'detail',
            'errorType',
            'errorDetail',
            'non_field_errors',
            ...additional_error_keys,
          ],
          keys(response.data),
        ).length
      ) {
        throw e;
      }

      const messages = flatMap([...getMessage(response.data)]);

      messages.forEach(message =>
        enqueueSnackbar(message, { variant: 'error' }),
      );

      throw e;
    }
  };

  return useSWRNext(key, modifiedFetcher, config);
};
