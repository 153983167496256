import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import { IncludeExcludeSwitch } from '@v2/components/ui/Switch/Switch.js';
import {
  AdvancedElementRowStyled,
  AdvancedTableStyled,
  FirstCellStyled,
} from '../styles';
import { optionalOptionType, tableSettings } from '../types';
import { RemoveCircleOutline } from '@mui/icons-material';

const StyledRemoveIcon = styled(RemoveCircleOutline)(({ theme }) => ({
  color: theme.palette.red[1],
  opacity: 0,
  transition: 'opacity 0.3s',
  marginLeft: theme.spacing(0.5),
  cursor: 'pointer',
  pointerEvents: 'none',
  '&:hover': {
    opacity: 1,
  },
  '.hover-row:hover &': {
    opacity: 1,
    pointerEvents: 'auto',
  },
  '.remove-all-hover &': {
    opacity: 1,
    pointerEvents: 'auto',
  },
}));

export const TableRight = ({
  data = [],
  onChange,
  onUpdate,
  renderCell,
  isTargeted,
  placeholderCell,
  settings,
  isRemoveAllHovered,
}) => {
  return (
    <TableContainer>
      <AdvancedTableStyled>
        <TableHead>
          <TableRow>
            <FirstCellStyled>Selected</FirstCellStyled>
            {settings.includable && (
              <TableCell
                sx={{
                  textAlign: 'right',
                  paddingRight: theme => theme.spacing(6.5),
                }}
              >
                Incl/Excl
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody data-testid="advanced-right-table-body">
          {data.map((v, i) => (
            <AdvancedElementRowStyled
              rowType="remove"
              className={`${v ? 'hover-row' : ''} ${isRemoveAllHovered ? 'remove-all-hover' : ''}`}
              targeted={v && isTargeted}
              onClick={() => {
                if (v) {
                  onChange(v);
                }
              }}
              highlighted={i % 2}
              key={v?.id ?? `placeholder-${i}`}
            >
              <FirstCellStyled>
                {v ? renderCell(v) : placeholderCell}
              </FirstCellStyled>

              {settings.includable && (
                <TableCell
                  align="right"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  {!!v && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        position: 'relative',
                        paddingRight: theme => theme.spacing(2.2),
                      }}
                    >
                      <IncludeExcludeSwitch
                        defaultChecked
                        checked={v.included}
                        onChange={e => {
                          onUpdate({ ...v, included: e.target.checked });
                        }}
                        disabled={!settings.separateInclExcl}
                      />
                      <StyledRemoveIcon
                        className="hover-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (v) {
                            onChange(v);
                          }
                        }}
                      />
                    </Box>
                  )}
                </TableCell>
              )}
            </AdvancedElementRowStyled>
          ))}
        </TableBody>
      </AdvancedTableStyled>
    </TableContainer>
  );
};

TableRight.propTypes = {
  data: PropTypes.arrayOf(optionalOptionType),
  onChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isTargeted: PropTypes.bool,
  renderCell: PropTypes.func.isRequired,
  placeholderCell: PropTypes.node,
  settings: tableSettings,
  isRemoveAllHovered: PropTypes.bool.isRequired,
};
