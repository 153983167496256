import useSWR, { useSWRConfig } from 'swr';
import { useCurrentSession } from '../currentSession';
import useSWRMutation from 'swr/mutation';
import { formatFileName } from '../upload';
import { useMemo } from 'react';

const buildCreativesCacheKey = (currentAdvertiser, params = {}) => {
  const url = '/creatives/';

  const cacheKey = {
    url,
    advertiser: currentAdvertiser.id ?? '-',
    ...params,
  };

  return cacheKey;
};

const buildCreativeCacheKey = (
  creativeId,
  currentAdvertiser,
  params = {},
) => {
  const url = `/creatives/${creativeId}`;

  const cacheKey = {
    url,
    advertiser: currentAdvertiser.id ?? '-',
    ...params,
  };

  return cacheKey;
};

export const useCreatives = ({ page, params = {} }) => {
  // getting currentAdvertiser to include its id on the cache key
  const { getV1, apiIsReady, currentAdvertiser } = useCurrentSession();
  const fetcher = ({ url, params }) =>
    getV1(url, params).then(res => res.data);

  if (page >= 1) {
    params['page'] = page;
  }

  const swr = useSWR(
    apiIsReady
      ? {
          url: '/creatives/',
          advertiser: currentAdvertiser.id ?? '-',
          params,
        }
      : null,
    fetcher,
  );

  const { data, error, isLoading } = swr;

  return {
    creatives: data?.results,
    nextPage: data?.next,
    previousPage: data?.previous,
    count: data?.count,
    error,
    isLoading,
  };
};

export const usePatchCreative = () => {
  const { patch, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const updateCreative = (url, { arg: { id, ...data } }) =>
    patch(`${url}${id}/`, data).then(res => res.data);

  const options = {
    onSuccess: updatedCreative => {
      const key = buildCreativeCacheKey(
        updatedCreative.id,
        currentAdvertiser,
      );
      mutate(key, updatedCreative, { populateCache: true });
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? `/creatives/` : null,
    updateCreative,
    options,
  );

  return { trigger, isMutating };
};

export const usePatchCreativeLineItem = () => {
  const { patch } = useCurrentSession();

  const updateCreative = (url, { arg: { id, ...data } }) =>
    patch(`${url}${id}/`, data).then(res => res.data);

  const options = {};

  const { trigger, isMutating } = useSWRMutation(
    `/creativelineitems/`,
    updateCreative,
    options,
  );

  return { trigger, isMutating };
};

export const useGetCreativeLineItemTrigger = () => {
  const { get, apiIsReady } = useCurrentSession();

  const { trigger } = useSWRMutation(
    apiIsReady ? `/creativelineitems/` : null,
    (url, { arg: id }) => {
      return get(`${url}?line_item_id=${id}`).then(
        res => res.data.results ?? [],
      );
    },
  );

  return { trigger };
};

export const useGetCreativeLineItems = adGroupId => {
  const { get, apiIsReady } = useCurrentSession();

  const { data = [] } = useSWR(
    apiIsReady && adGroupId ? `/creativelineitems/` : null,
    url => {
      return get(`${url}?line_item_id=${adGroupId}`).then(
        res => res.data.results,
      );
    },
  );

  return {
    data,
  };
};

export const useCreativeVideoUpload = () => {
  const { postV1, apiIsReady } = useCurrentSession();

  const createCreative = (url, { arg: { data, ...rest } }) => {
    return postV1(`${url}`, data, {
      ...rest,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(res => res.data);
  };

  const options = {};

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? '/video_assets/' : null,
    createCreative,
    options,
  );

  return {
    trigger: ({ file, advertiser, listener }) => {
      const abortController = new AbortController();
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', formatFileName(file.name));
      formData.append('advertiser', advertiser);
      formData.append('media_type', file.type);

      const result = trigger({
        data: formData,
        onUploadProgress: listener,
        signal: abortController.signal,
      });

      return {
        result,
        cancel: () => abortController.abort(),
      };
    },
    isMutating,
  };
};

export const usePostCreative = () => {
  const { post, apiIsReady } = useCurrentSession();

  const createCreative = (url, { arg }) =>
    post(`${url}`, arg).then(res => res.data);

  const options = {};

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? `/creatives/` : null,
    createCreative,
    options,
  );

  return { trigger, isMutating };
};

export const useGetCreatives = (options = {}) => {
  const { get, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { disabled = false, ...otherOptions } = options;

  const fetcher = ({ url, params }) =>
    get(url, { params }).then(res => res.data);

  const params = {
    expand: 'lineitem_set',
  };

  const { data, mutate, isLoading } = useSWR(
    apiIsReady && !disabled
      ? buildCreativesCacheKey(currentAdvertiser, params)
      : null,
    fetcher,
    otherOptions,
  );

  const items = useMemo(() => data?.results ?? [], [data]);

  return {
    data,
    items,
    mutate: d => mutate(d, otherOptions),
    invalidate: mutate,
    isLoading,
  };
};

export const useAdGroupCreatives = adGroup => {
  const { get, apiIsReady } = useCurrentSession();
  const fetcher = urls => {
    return Promise.all(urls.map(url => get(url).then(res => res.data)));
  };
  const urls =
    adGroup.creatives?.map(creativeId => ({
      url: `/creatives/${creativeId}/`,
      adGroupId: adGroup.id,
    })) ?? [];

  const swr = useSWR(
    apiIsReady && urls.length > 0 ? urls.map(item => item.url) : null,
    fetcher,
  );
  const { data, error, isLoading } = swr;

  return {
    adGroupsCreatives: data,
    error,
    isLoading,
  };
};
