import { useContext, useMemo } from 'react';
import AdvertiserContext from '../AdvertiserContext';
import { Themes } from '../../constants';

export const useCopy = copies => {
  const adContext = useContext(AdvertiserContext);

  return useMemo(
    () => {
      const copy = Object.keys(copies[Themes.DEFAULT]).reduce((acc, key) => {
        acc[key] =
          // We explicitly check for `undefined` here because the theme copy
          // might be `null`, which should be preserved as meaning "don't show
          // any copy here", whereas `undefined` means "use the default copy
          // here"
          copies[adContext.theme][key] !== undefined ?
            copies[adContext.theme][key] :
            copies[Themes.DEFAULT][key];
        return acc;
      }, {});
      return copy;
    },
    [adContext.theme, copies]
  );
};
