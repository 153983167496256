export const distinctNames = {
  place: 'cities',
  region: 'states',
  country: 'country',
  countries: 'countries',
  dma: 'dmas',
  zip: 'zipcodes',
  zipcodes: 'zipcodes',
  zipList: 'zipcodesList',
  postcode: 'zipcodes',
};
