import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

import { useCurrentSession } from '../currentSession';
import { useCallback, useEffect, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { last, set } from 'lodash';

const buildAdGroupCacheKey = (adGroupId, currentAdvertiser, params = {}) => {
  const url = `/lineitems/${adGroupId}`;

  const cacheKey = {
    url,
    advertiser: currentAdvertiser.id ?? '-',
    ...params,
  };

  return cacheKey;
};

export const usePatchStaticDisplayAdGroup = options => {
  const { patch, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();
  const { onSuccess: os, ...newOptions } = options;

  const updateAdGroup = (url, { arg: { id, ...data } }) =>
    patch(`${url}${id}/`, data).then(res => res.data);

  const defaultOptions = {
    onSuccess: updatedAdGroup => {
      const key = buildAdGroupCacheKey(updatedAdGroup.id, currentAdvertiser);
      mutate(key, updatedAdGroup, { populateCache: true });
      if (typeof os === 'function') os();
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? `/staticdisplaylineitems/` : null,
    updateAdGroup,
    { ...defaultOptions, ...newOptions },
  );

  return { trigger, isMutating };
};

export const useDeleteStaticDisplayAdGroup = options => {
  const { del, apiIsReady } = useCurrentSession();

  const deleteAdGroup = (url, { arg: id }) =>
    del(`${url}${id}/`).then(res => res.data);

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? `/staticdisplaylineitems/` : null,
    deleteAdGroup,
    {
      revalidate: true,
      ...options,
    },
  );

  return { trigger, isMutating };
};

export const useCreateStaticDisplayAdGroup = options => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();
  const { onSuccess: os, ...newOptions } = options;

  const createStaticDisplayAdGroup = ({ url }, { arg }) =>
    post(url, arg).then(res => res.data);

  const url = '/staticdisplaylineitems/';

  const defaultOptions = {
    onSuccess: newAdGroup => {
      const key = buildAdGroupCacheKey(newAdGroup.id, currentAdvertiser);
      mutate(key, newAdGroup, { populateCache: true });
      if (typeof os === 'function') os();
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? { url } : null,
    createStaticDisplayAdGroup,
    { ...defaultOptions, ...newOptions },
  );

  return { trigger, isMutating };
};

export const useCampaignStaticDisplayAdGroupsPage = (campaignId, options) => {
  const { get, apiIsReady, currentAdvertiser, del } = useCurrentSession();

  const url = '/staticdisplaylineitems/';

  const { data, error, isLoading, setSize, mutate } = useSWRInfinite(
    (index, previousPageData) => {
      if (
        !apiIsReady ||
        !campaignId ||
        (previousPageData && !previousPageData.next)
      )
        return null;

      if (!index)
        return {
          url,
          advertiser: currentAdvertiser.id ?? '-',
          params: {
            campaign: campaignId,
          },
        };

      return {
        url,
        advertiser: currentAdvertiser.id ?? '-',
        params: {
          campaign: campaignId,
          page: index + 1,
        },
      };
    },
    ({ url, params }) => get(url, params).then(res => res.data),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...options,
    },
  );

  const add = useCallback(
    predefinedData =>
      mutate(prevData => {
        const lastPage = { ...last(prevData) };

        const newResult = {
          isDisplay: true,
          id: (lastPage?.results?.length ?? 0) + 1,
          temporary: true,
          creatives: [],
          ...predefinedData,
        };

        return [
          ...(prevData?.slice(0, -1) ?? []),
          {
            ...lastPage,
            results: [...(lastPage?.results ?? []), newResult],
          },
        ];
      }, false),
    [mutate],
  );

  const remove = useCallback(
    id =>
      mutate(async prevData => {
        if (!id) return prevData;

        const pageWithItem = prevData.find(page =>
          page.results.find(result => result.id === id),
        );

        const { temporary = false } =
          pageWithItem?.results.find(result => result.id === id) || {};

        if (!temporary) {
          await del(`${url}${id}/`);
        }

        return prevData.map(page => {
          const updatedResults = page.results.filter(
            result => result.id !== id,
          );

          return set({ ...page }, 'results', updatedResults);
        });
      }, false),
    [mutate],
  );

  useEffect(() => {
    if (data?.next) {
      setSize(prev => prev + 1);
    }
  }, [data]);

  const items = useMemo(
    () =>
      data
        ? [].concat(...data.map(page => page?.results) ?? [])
        : [],
    [data],
  );

  return {
    items,
    data,
    remove,
    error,
    isLoading,
    mutate,
    add,
  };
};
