import { FormProvider, useForm } from 'react-hook-form';

import React, { lazy, useCallback, useEffect, useMemo } from 'react';

import {
  useGetCampaign,
  usePatchCampaign,
  usePostCampaign,
} from '@apis/campaigns';
import { useWizardSection, sectionProps } from '@components/Wizard';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCurrentSession } from '@hooks';
import {
  initialValues,
  validationSchema,
} from '@v2/components/forms/CampaignForm/formConfig';
import { defaults } from 'lodash';
import { handleErrors, removeNullValues } from '../utils';
import { labels } from './constants';
import {
  parseApiDataToFormValues,
  parseFormValuesToApiData,
} from './panes/CampaignInfoPane/utils';
import { SWRConfig } from 'swr';
import { withCamelCase } from '@swr';
import { fields } from '@v2/components/forms/CampaignForm/constants';
import PropTypes from 'prop-types';

const CampaignInfoPane = lazy(() => import('./panes/CampaignInfoPane'));
const AdvancedCampaignDeliveryOptionsPane = lazy(
  () => import('./panes/AdvancedCampaignDeliveryOptionsPane'),
);

const CampaignSection = ({
  campaignId,
  sectionKey,
  campaignStaticDisplayAdGroups,
  onRetargetDisplayToggleChange,
}) => {
  const { currentAdvertiser } = useCurrentSession();
  const { campaign } = useGetCampaign(campaignId);
  const resolver = yupResolver(validationSchema());
  const { updateEntityState, activePane, isActivePane, goToNext, goTo } =
    useWizardSection({
      key: sectionKey,
    });

  const { trigger: updateCampaign } = usePatchCampaign(campaignId);
  const { trigger: postCampaign } = usePostCampaign();

  const data = useMemo(
    () =>
      parseApiDataToFormValues({
        ...campaign,
        campaignStaticDisplayAdGroups,
        advertiserName: currentAdvertiser.name,
      }),
    [campaign, currentAdvertiser.name],
  );

  const methods = useForm({
    mode: 'onChange',
    values: defaults(data, initialValues()),
    resetOptions: {
      keepDirtyValues: true,
    },
    resolver,
  });

  const handleSubmit = useCallback(
    async values => {
      const data = removeNullValues(
        parseFormValuesToApiData({
          ...values,
          advertiser: currentAdvertiser.id,
        }),
      );

      const { id } = campaignId
        ? await updateCampaign(data)
        : await postCampaign(data);

      if (campaignId) {
        goToNext({
          checkDirty: false,
        });
      } else {
        goTo(
          {
            campaignId: id,
            sectionId: 1,
          },
          {
            checkDirty: false,
          },
        );
      }
    },
    [campaignId, currentAdvertiser.id, goToNext, goTo],
  );

  const {
    formState: { isDirty },
    setValue,
  } = methods;

  const handleRetargetDisplayToggleChange = (event, displayPctField) => {
    onRetargetDisplayToggleChange(event);

    setValue(fields.displayPct.path, event.target.checked);
    displayPctField.onChange(event.target.checked);
  };

  useEffect(() => {
    updateEntityState({
      dirty: isDirty,
    });
  }, [updateEntityState, isDirty]);

  return (
    <FormProvider {...methods}>
      {(!activePane || isActivePane(labels.nameDates.value)) && (
        <CampaignInfoPane
          onRetargetDisplayToggleChange={handleRetargetDisplayToggleChange}
          campaignStaticDisplayAdGroups={campaignStaticDisplayAdGroups}
          onSubmit={handleErrors(handleSubmit, {
            setError: methods.setError,
            mappedFields: {
              budget: fields.dailyBudget.path,
            },
          })}
          campaignId={campaignId}
          sectionKey={sectionKey}
        />
      )}
      {isActivePane(labels.objective.value) && (
        <AdvancedCampaignDeliveryOptionsPane />
      )}
    </FormProvider>
  );
};

const CampaignSectionWrapper = props => {
  return (
    <SWRConfig
      value={parent => ({
        use: [...parent.use, withCamelCase],
      })}
    >
      <CampaignSection {...props} />
    </SWRConfig>
  );
};

CampaignSection.propTypes = {
  campaignStaticDisplayAdGroups: PropTypes.array,
  onRetargetDisplayToggleChange: PropTypes.func,
  ...sectionProps,
};

CampaignSectionWrapper.propTypes = {
  ...CampaignSection.propTypes,
};

export default CampaignSectionWrapper;
