import Papa from 'papaparse';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import sanitize from 'sanitize-html';
import { useCaretOffset } from './useCaretOffset';

export const useAddZipCodes = ({ onChange, value, wrongZipCodes, html, setHtml }) => {
  const editorRef = useRef();
  const [showDropzone, setShowDropzone] = useState(false);
  const {
    caretOffset,
    saveSelection,
    restoreSelection
  } = useCaretOffset(editorRef);

  const handleZipCodeChange = e => {
    const sanitizeConfig = {
      allowedTags: ["span"],
      allowedAttributes: {
        span: ["class"],
      },
    };

    // Replace &nbsp with space to prevent unnecessary re-render
    const zipCodeText = e.target.value.replace(/&nbsp;/g, ' ');
    const san = sanitize(zipCodeText, sanitizeConfig);
    setHtml(san);
    caretOffset.current = saveSelection();

    // Split zip codes by commas and filter out empty values
    const zipCodeArray = san
      .replace(/<\/?(span)(.*?)>/gi, ',')
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item !== '');

    // Process each zip code
    const codes = zipCodeArray.map((zip, index, arr) => {
      // If zip code length is less than 5, it should be invalid once a comma follows it
      if (zip.length > 0 && zip.length < 5) {
        const isLastZipCode = index === arr.length - 1;
        // If it's the last zip code and no comma follows, it's temporarily valid
        if (isLastZipCode && !san.trim().endsWith(',')) {
          return { zip, valid: true }; // Temporarily valid if no comma follows
        }
        return { zip, valid: false }; // Invalid if a comma follows
      }

      // If zip code is exactly 5 characters, validate it
      if (zip.length === 5) {
        const isValid = /^[0-9]{5}$/.test(zip); // Validate 5-digit zip code
        return { zip, valid: isValid };
      }

      // For zip codes longer than 5 characters or other invalid inputs, mark as invalid
      return { zip, valid: false };
    });

    // Update the form or component state with the processed zip codes
    onChange(codes);
  };

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    if (file && file.type === 'text/csv') {
      Papa.parse(file, {
        complete: result => {
          const zipCodesFromFile = result.data.map(row => row[0]);
          setHtml(zipCodesFromFile.join(', '));
          onChange(zipCodesFromFile.map(zip => ({ zip })));
          setShowDropzone(false);
        },
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDragOver: event => event.preventDefault(),
    onDragEnter: () => setShowDropzone(true),
    onDragLeave: () => setShowDropzone(false),
    accept: '.csv',
  });

  const actualValue = useMemo(
    () =>
      value.map(v => ({
        ...v,
        valid: !wrongZipCodes.includes(v.zip),
      })),
    [value, wrongZipCodes],
  );

  useEffect(() => {
    let newHtml = sanitize(html, { allowedTags: [] });

    actualValue.forEach(({ zip, valid }, index, arr) => {
      const isLastZipCode = index === arr.length - 1;

      // Mark zip codes as errored if they are invalid or short (< 5) and followed by a comma
      if ((!valid && zip.length === 5) || zip.length > 5 ||
        (zip.length < 5 && !(isLastZipCode && !html.trim().endsWith(',')))) {
        const regex = new RegExp(`\\b${zip}\\b`, 'g');
        newHtml = newHtml.replace(regex, `<span class="errored">${zip}</span>`);
      }
    });
    setHtml(newHtml);
    if (caretOffset.current !== null) restoreSelection(caretOffset.current);
  }, [html, actualValue]);

  return {
    editorRef,
    actualValue,
    getRootProps,
    getInputProps,
    isDragActive,
    handleZipCodeChange,
    showDropzone,
  };
};
