export const FormFields = {
  items: {
    NAME: 'items',
    fields: {
      ID: 'id',
      NAME: 'name',
      PROGRESS: 'progress',
    },
  },
};
