import { AddCircleOutline, SearchOutlined } from '@mui/icons-material';
import { InputAdornment, Stack, styled } from '@mui/material';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import {
  ActionButtonStyled,
  AdvancedHeaderBoxStyled,
  TextFieldStyled,
} from '../styles';
import { optionalOptionType } from '../types';
import { useCopy } from '@hooks/copy';
import { Copies } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const SearchFieldStyled = styled(TextFieldStyled)(({ theme }) => ({
  background: theme.palette.common.white,
  width: '100%',
  maxWidth: theme.spacing(41.25),
  minWidth: theme.spacing(20),
  marginRight: theme.spacing(4.25),

  '& .MuiOutlinedInput-root': {
    height: theme.spacing(4.25),

    '& input': {
      fontSize: '0.75rem',
      color: theme.palette.text.primary,
    },

    '& fieldset': {
      borderColor: `${theme.palette.grey['9']} !important`, // Default border color
    },

    '&:hover fieldset': {
      borderColor: `${theme.palette.grey['9']} !important`, // Prevent hover color change
    },

    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.blue['6']} !important`, // Prevent active color change
    },
  },
  '.MuiInputBase-input': {
    color: theme.palette.grey['5'],
  }
}));

// Define the styled component for the search icon
const StyledSearchIcon = styled(SearchOutlined)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  color: theme.palette.grey['2'],
}));

// Define the styled component for the clear icon (FontAwesome "x" icon)
const StyledClearIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  color: theme.palette.grey[6],
  cursor: 'pointer',
}));

export const TableLeftHeader = ({
  data = [],
  onFilter,
  onAddAll,
  onAddAllHovered,
  setSearch = () => {},
}) => {
  const Copy = useCopy(Copies);

  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setSearch(value);
    onFilter(value);
  };

  return (
    <AdvancedHeaderBoxStyled>
      <Stack flex={1} sx={{ paddingRight: theme => theme.spacing(4.25) }}>
        {onFilter && (
          <SearchFieldStyled
            size="small"
            value={searchValue}
            onChange={(e) => handleSearchChange(e.target.value)}
            placeholder="Search by name or ID..."
            InputProps={{
              'data-testid': 'advanced-filter-input',
              endAdornment: (
                <InputAdornment position="end">
                  {searchValue ? (
                    <StyledClearIcon
                      icon={faTimes}
                      onClick={() => handleSearchChange('')}
                    />
                  ) : (
                    <StyledSearchIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      </Stack>
      <ActionButtonStyled
        disabled={!data.length}
        actionType="add"
        onClick={onAddAll}
        variant="text"
        color="primary"
        data-testid="advanced-add-all"
        onMouseEnter={() => onAddAllHovered?.(true)}
        onMouseLeave={() => onAddAllHovered?.(false)}
      >
        <AddCircleOutline />
        <span>{Copy.addAll}</span>
      </ActionButtonStyled>
    </AdvancedHeaderBoxStyled>
  );
};

TableLeftHeader.propTypes = {
  data: PropTypes.arrayOf(optionalOptionType),
  onFilter: PropTypes.func,
  onAddAll: PropTypes.func,
  onAddAllHovered: PropTypes.func,
  setSearch: PropTypes.func,
};
