import { useRef } from 'react';

export const useCaretOffset = (editorRef) => {
  const caretOffset = useRef(null);

  const saveSelection = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return null;

    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(editorRef.current);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    const caretPosition = preCaretRange.toString().length;
    return caretPosition;
  };

  const restoreSelection = (caretOffset) => {
    const selection = window.getSelection();
    selection.removeAllRanges();

    const range = document.createRange();
    let charCount = 0;

    const setRange = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textLength = node.textContent.length;
        if (charCount + textLength >= caretOffset) {
          range.setStart(node, caretOffset - charCount);
          range.setEnd(node, caretOffset - charCount);
          selection.addRange(range);
          return true;
        }
        charCount += textLength;
      } else {
        for (let i = 0; i < node.childNodes.length; i++) {
          if (setRange(node.childNodes[i])) {
            return true;
          }
        }
      }
      return false;
    };

    setRange(editorRef.current);
  };

  return {
    caretOffset,
    saveSelection,
    restoreSelection
  };
};
