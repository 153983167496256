import { Avatar, Stack, Typography } from '@mui/material';
import React from 'react';
import { Hint } from '../../../ui/Hint';
import { HighlightedContent } from '../../Advanced';
import { AdvancedBox } from '../AdvancedBox';
import { inventoryType } from '../types';
import { AdvancedTable } from './AdvancedTable';
import {
  AvatarImageStyled,
  CellContentStyled,
  ReactiveIconStyled,
  TableCellAdditionalDataBlockStyled,
} from '../styles';
import { useAdvancedCustomInventoryTable } from './hooks/useAdvancedCustomInventoryTable';
import { PropTypes } from 'prop-types';
import { fromHighlightedContent } from '../../Advanced/utils';

const Cell = ({ data, ...props }) => {
  return (
    <CellContentStyled
      featured={data.featured}
      direction="row"
      color="chart.6"
      spacing={2}
      {...props}
    >
      <Avatar
        variant="square"
        sx={{
          bgcolor: data.icon?.url ? 'transparent' : 'grey.8',
        }}
      >
        {data.icon?.url ? (
          <AvatarImageStyled alt="" src={data.icon?.url} />
        ) : (
          <span />
        )}
      </Avatar>
      <Stack>
        <Stack direction="row" alignItems="center" spacing={1.25}>
          <HighlightedContent data={data.display_name} />
          <Hint color="grey.6">
            {fromHighlightedContent(data.display_name)}
          </Hint>
          {data.recommended && <ReactiveIconStyled name="reactive" />}
        </Stack>
        <Stack>
          <TableCellAdditionalDataBlockStyled>
            {data.info}
          </TableCellAdditionalDataBlockStyled>
        </Stack>
      </Stack>
    </CellContentStyled>
  );
};

Cell.propTypes = {
  ...Stack.propTypes,
  data: inventoryType,
};

export const AdvancedCustomInventoryTable = ({
  onClose,
  onSubmit,
  data,
  ...props
}) => {
  const {
    tab,
    tabs,
    selected,
    form,
    legend,
    setSelected,
    data: passedData,
    setFilter,
    setTab,
  } = useAdvancedCustomInventoryTable(data);

  return (
    <AdvancedBox
      onClose={onClose}
      header={<Typography variant="h3">Custom Inventory</Typography>}
      {...props}
    >
      <AdvancedTable
        {...props}
        onClose={onClose}
        data={passedData}
        onSubmit={onSubmit}
        selected={selected}
        form={form}
        selectedTab={tab}
        onChangeTab={setTab}
        tabs={tabs}
        onFilter={setFilter}
        renderCell={v => <Cell data={v} />}
        onChange={v => {
          setSelected(v);
        }}
        legend={legend}
      />
    </AdvancedBox>
  );
};

AdvancedCustomInventoryTable.propTypes = {
  ...AdvancedBox.propTypes,
  data: PropTypes.object,
};
