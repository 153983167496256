import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { InputAdornment, TextField } from '@mui/material';

import { NumericFormat } from 'react-number-format';

const NumericFormatCustom = forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

NumericFormatCustom.displayName = 'NumericFormatCustom';

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const BudgetTextField = forwardRef(
  ({ InputProps: { ...inputProps } = {}, ...props }, ref) => {
    return (
      <TextField
        {...props}
        ref={ref}
        InputProps={{
          ...inputProps,
          inputComponent: NumericFormatCustom,
          inputProps: {
            ...props,
            'data-testid': 'daily-campaign-budget'
          },
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    );
  },
);

BudgetTextField.displayName = 'BudgetTextField';

BudgetTextField.propTypes = {
  ...TextField.propTypes,
};
export default BudgetTextField;
