import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { RHFTextField } from '../../RHFTextField';
import { joinSchema } from './validations';
import { yupResolver } from '@hookform/resolvers/yup';
import stateOptions from '../../utilStates';
import { RHFSelect } from '../../RHFSelect';
import { categories as sensitiveCategories } from '../../../constants/sensitiveCategories';
import { RHFCheckbox } from '../../RHFCheckbox';
import { useGetAdvertiserCategories } from '@apis/advertiserCategories';
import { outcomes } from '@constants/outcomes';

const JoinForm = ({ platformInvite, onSubmit }) => {
  const { data: categories, isLoading } = useGetAdvertiserCategories();
  const { context } = platformInvite ?? {};
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = useForm({
    resolver: yupResolver(joinSchema),
    defaultValues: {
      accepted_terms: false,
      organization_name:
        context?.legal_company_name ?? context?.organization_name ?? '',
      street_address_1: context?.street_address ?? '',
      street_address_2: context?.street_address_2 ?? '',
      city: context?.city,
      state: context?.state || null,
      zip_code: context?.zip_code ?? '',
      phone: context?.phone_number ?? '',
      advertiser_name: context?.advertiser_name ?? '',
      advertiser_website_address: context?.website_domain ?? 'https://www.',
      category: null,
      primary_outcome: null,
      sensitive: null,
    },
  });

  useEffect(() => {
    if (context?.category && categories?.length > 0) {
      const matchingCategory = categories.find(
        cat => cat.iab_id === context?.category,
      );
      if (matchingCategory) {
        setValue('category', matchingCategory.url);
      }
    }
  }, [context?.category, categories]);

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      data-testid="join-form"
    >
      <Grid
        container
        spacing={4}
        sx={{
          marginBottom: {
            xs: 18,
            sm: 0,
          },
        }}
      >
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RHFTextField
                label="Company Name"
                name="organization_name"
                control={control}
                data-testid="organization_name"
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                label="Company Street Address"
                name="street_address_1"
                control={control}
                data-testid="street_address_1"
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                label=""
                placeholder="Street Address 2 (Optional)"
                name="street_address_2"
                control={control}
                data-testid="street_address_2"
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                label="City"
                name="city"
                control={control}
                data-testid="city"
              />
            </Grid>
            <Grid item xs={8}>
              <RHFSelect label="State" control={control} name="state">
                {stateOptions.map(m => (
                  <MenuItem key={m.abbreviation} value={m.abbreviation}>
                    {m.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={4}>
              <RHFTextField
                label="Zip Code"
                name="zip_code"
                control={control}
                data-testid="zip_code"
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                label="Company Phone Number"
                name="phone"
                control={control}
                data-testid="phone"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RHFTextField
                label="Advertiser Name"
                name="advertiser_name"
                control={control}
                data-testid="advertiser_name"
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                label="Advertiser Website Address"
                name="advertiser_website_address"
                control={control}
                data-testid="advertiser_website_address"
              />
            </Grid>

            <Grid item xs={12}>
              <RHFTextField
                select
                label="Advertiser Category"
                name="category"
                control={control}
                loading={isLoading}
              >
                {categories?.map(c => (
                  <MenuItem key={c.id} value={c.url}>
                    {c.name}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Grid>
            <Grid item xs={12}>
              <RHFSelect
                label="Primary Outcome"
                name="primary_outcome"
                control={control}
              >
                {outcomes.map(k => (
                  <MenuItem key={k.value} value={k.value}>
                    {k.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>

            <Grid item xs={12}>
              <RHFTextField
                select
                label="Sensitive Category"
                name="sensitive"
                control={control}
              >
                {sensitiveCategories.map(s => (
                  <MenuItem key={s.value} value={s.value}>
                    {s.label}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Grid>
            <Grid item xs={12}>
              <RHFCheckbox
                name="accepted_terms"
                data-testid="accepted_terms"
                label={
                  <Typography>
                    I understand that failure to disclose{' '}
                    <Link
                      sx={{ textDecoration: 'none' }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.tvscientific.com/platform-master-agreement-demand/"
                    >
                      sensitive category
                    </Link>{' '}
                    may result in removal from the tvScientific platform.
                  </Typography>
                }
                control={control}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          zIndex: 1,
          position: 'fixed',
          width: '100%',
          padding: 4,
          bottom: 0,
          left: 0,
          background: '#EDF1F2',
          boxShadow:
            '0px -5px 5px 0px rgba(24, 28, 32, 0.07), 0px -3px 14px 0px rgba(24, 28, 32, 0.0919), 0px -8px 10px 0px rgba(24, 28, 32, 0.05)',
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box>
            <Button
              disabled={isSubmitting}
              size="large"
              data-testid="join_button"
              fullWidth
              variant="contained"
              type="submit"
            >
              Accept &amp; Continue
            </Button>
          </Box>
        </Container>
      </Box>
    </form>
  );
};

JoinForm.propTypes = {
  platformInvite: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    context: PropTypes.shape({
      advertiser_name: PropTypes.string,
      city: PropTypes.string,
      legal_company_name: PropTypes.string,
      phone_number: PropTypes.string,
      organization_name: PropTypes.string,
      state: PropTypes.string,
      street_address: PropTypes.string,
      street_address_2: PropTypes.string,
      website_domain: PropTypes.string,
      zip_code: PropTypes.string,
      category: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default JoinForm;
