import { useCampaignAdGroupsPage } from '@apis/adGroups';
import { useGetAllowedTargeting } from '@apis/allowedTargeting';
import { useGetAudiences } from '@apis/audiences';
import { useGetAllBidStrategies } from '@apis/bidStrategy';
import { useGetBundles } from '@apis/bundles';
import { useGetCampaign } from '@apis/campaigns';
import { useGetCreatives } from '@apis/creatives';
import { useDisplays } from '@apis/displays';
import { useCampaignStaticDisplayAdGroupsPage } from '@apis/staticDisplayAdGroups';
import { useDomain } from '@hooks';
import { weightingRotationOptions } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCreative';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useWizardSection } from '../../../Wizard/hooks/useWizardSection';
import { bundleTypes } from '../constants';
import { SCROLL_DELAY } from '../../constants';
import { useScrollTo } from '../../hooks/useScrollTo';
import { fields } from '../formConfig';
import { useGroupSectionForm } from './useGroupSectionForm';
import { useGroupSectionSubmit } from './useGroupSectionSubmit';
import { useGroupSectionsInfo } from './useGroupSectionsInfo';
import { useInventoryAvailability } from './useInventoryAvailabilty';

export const useGroupSection = ({
  campaignId,
  adGroup,
  sectionKey,
  isDisplay,
}) => {
  const { ref } = useScrollTo(SCROLL_DELAY);
  const { enqueueSnackbar } = useSnackbar();
  const domain = useDomain();
  const { updateEntityState, activePane } = useWizardSection({
    key: sectionKey,
  });

  const { items: audiences } = useGetAudiences();
  const { campaign } = useGetCampaign(campaignId);
  const { data: bidStrategies } = useGetAllBidStrategies();

  const { mutate: mutateCampaignAdGroups } =
    useCampaignAdGroupsPage(campaignId);

  const { mutate: mutateCampaignStaticDisplayAdGroups } =
    useCampaignStaticDisplayAdGroupsPage(campaignId);

  const mutateAdGroupsOption = { onSuccess: () => mutateCampaignAdGroups() };
  const mutateStaticDisplayAdGroupsOption = {
    onSuccess: () => mutateCampaignStaticDisplayAdGroups(),
  };

  const { data: targeting } = useGetAllowedTargeting();

  const type = domain.peacock ? bundleTypes.PEACOCK : null;
  const { items: bundles } = useGetBundles(type);

  const { displays: displayLib } = useDisplays(
    { page: 1 },
    {
      disabled: !isDisplay,
    },
  );
  const { items: lib } = useGetCreatives({
    disabled: isDisplay,
  });

  const library = useMemo(
    () => (isDisplay ? displayLib : lib),
    [isDisplay, displayLib, lib],
  );

  const { form } = useGroupSectionForm({
    adGroup,
    bidStrategies,
    bundles,
    library,
    audiences,
    targeting,
    campaign,
  });

  const { availability: inventoryAvailability } = useInventoryAvailability({
    form,
    adGroup,
  });

  const { info } = useGroupSectionsInfo({ form });

  const { submit, mutating } = useGroupSectionSubmit({
    campaign,
    adGroup,
    mutateAdGroupsOption,
    mutateStaticDisplayAdGroupsOption,
    form,
    sectionKey,
    isDisplay,
  });

  const handleSubmitCreatives = ({ creatives, weightingRotation }) => {
    form.setValue(fields.creatives.path, creatives);
    form.setValue(
      fields.weightingRotation.path,
      weightingRotation
        ? weightingRotationOptions.weighted
        : weightingRotationOptions.random,
    );
  };
  const handleSubmitCustomInventory = ({ items }) => {
    form.setValue(fields.advancedCustomInventory.path, items);
  };

  const handleSubmitZipCodes = data => {
    form.setValue(fields.geography.path, [
      ...form.getValues(fields.geography.path),
      ...data,
    ]);
  };

  const handleSubmitAudiences = ({ items }) => {
    form.setValue(fields.advancedAudience.path, items);
  };

  const handleSubmitAdvancedInventory = items => {
    form.setValue(fields.advancedInventory.path, items);
  };

  const handleSubmitAdvancedDelivery = data => {
    form.setValue(
      fields.configureAdGroupFrequencyCap.path,
      data[fields.configureAdGroupFrequencyCap.path],
    );
    form.setValue(
      fields.configureAdGroupDayparting.path,
      data[fields.configureAdGroupDayparting.path],
    );
  };

  const handleGeneralError = message => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  const {
    formState: { isDirty },
  } = form;

  useEffect(() => {
    return () => {
      lib.forEach(({ preview_url }) => {
        URL.revokeObjectURL(preview_url);
      });
    };
  }, [lib]);

  useEffect(() => {
    updateEntityState(sectionKey, {
      dirty: isDirty,
    });
  }, [isDirty, updateEntityState]);

  return {
    ref,
    form,
    activePane,
    campaign,
    sectionsInfo: info,
    mutating,
    submit,
    submitCreatives: handleSubmitCreatives,
    submitZipCodes: handleSubmitZipCodes,
    submitCustomInventory: handleSubmitCustomInventory,
    submitAdvancedInventory: handleSubmitAdvancedInventory,
    submitAudiences: handleSubmitAudiences,
    submitAdvancedDelivery: handleSubmitAdvancedDelivery,
    inventoryAvailability,
    generalError: handleGeneralError,
    isActivePane: paneKey => activePane === paneKey,
  };
};
