import moment from 'moment-timezone';
import { convertToStatus } from '../../../utils';
import { extractIdFromUrl } from '@cypress/utils/helpers';
import { accountCategoriesObjectiveMap } from '@components/accountCategories';

export const parseApiDataToFormValues = values => {
  const {
    id,
    startDate,
    endDate,
    budget,
    dailyBudget,
    draft,
    experimentType,
    experimentPlan,
    freqCaps = [],
    displayPct,
  } = values || {};

  const advancedOptions = {
    experimentType,
    experimentPlan,
    freqCaps,
  };

  const defaultEndDate = moment().add(1, 'month').endOf('month');
  const defaultEndTime = moment(defaultEndDate).endOf('day');

  return {
    ...values,
    draft: draft ?? true,
    status: convertToStatus(values),
    startDate: moment(startDate ?? undefined),
    startTime: startDate ? moment(startDate) : moment(),
    endDate: endDate ? moment(endDate) : id ? null : defaultEndDate,
    endTime: endDate ? moment(endDate) : id ? null : defaultEndTime,
    budgetType: 'daily_budget',
    lifetimeBudget: budget ?? undefined,
    dailyBudget: dailyBudget ?? undefined,
    displayPct: Number(displayPct) ? true : false,
    advancedOptions: {
      ...advancedOptions,
      isIncremental: !!experimentType,
      isExperiment: !!experimentPlan,
      isCapAds: freqCaps.length > 0,
      freqCaps,
      experimentPlan: experimentPlan ?? undefined,
      experimentType: experimentType ?? undefined,
    },
  };
};

export const parseFormValuesToApiData = values => {
  const {
    name,
    advertiser,
    startDate,
    startTime,
    endDate,
    endTime,
    budgetType,
    dailyBudget,
    lifetimeBudget,
    objective,
    advancedOptions,
    active,
    draft,
    displayPct,
  } = values || {};

  const {
    isIncremental,
    isExperiment,
    isCapAds,
    experimentPlan,
    experimentType,
    freqCaps,
  } = advancedOptions || {};

  return {
    name,
    budget: budgetType === 'lifetime_budget' ? lifetimeBudget : dailyBudget,
    budget_type: budgetType,
    start_date: combineDateAndTime(startDate, startTime ?? moment()),
    end_date: combineDateAndTime(endDate, endTime),
    experiment_plan: isExperiment ? experimentPlan : undefined,
    experiment_type: isIncremental ? experimentType : undefined,
    freq_caps: isCapAds ? freqCaps : [],
    advertiser,
    active: !!active,
    draft: !!draft,
    display_pct: displayPct ? 0.1 : 0,
    objective,
  };
};

export const getRecommendedCampaignObjectForAdvertiser = advertiser => {
  if (advertiser?.category) {
    const categoryId = extractIdFromUrl(advertiser.category);
    if (categoryId) {
      return Object.keys(accountCategoriesObjectiveMap).find(key => {
        return accountCategoriesObjectiveMap[key].includes(
          parseInt(categoryId, 10),
        );
      });
    }
  }
  return null;
};

const combineDateAndTime = (date, time) => {
  if (!date || !time) return null;
  const dateMoment = moment(date);
  const timeMoment = moment(time);

  const combinedMoment = moment({
    year: dateMoment.year(),
    month: dateMoment.month(),
    day: dateMoment.date(),
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    second: timeMoment.second(),
  });

  return combinedMoment;
};
