import React, { useEffect, useState, useContext, memo, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, CircularProgress, Fade, Grid, Container, Paper, IconButton } from '@mui/material';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import { useForm, FormProvider } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import moment from "moment-timezone";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import _ from 'lodash';

import { formatDateTime, getTargetingList } from '../util';
import { useAPI } from '../hooks/api';
import { useAdGroups } from '../hooks/adGroups';
import { useCampaigns } from '../hooks/campaigns';
import { useGeo } from '../hooks/geo';
import { useTracking } from '../hooks/tracking';
import { useUtil } from '../hooks/util';
import { useSaveExit } from '../hooks/saveExit';
import { useLogRocket } from '../hooks/logRocket';
import { useSelectEntireUS, useLoader, useUser } from '../hooks';
import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import Breadcrumbs from '../Breadcrumbs';
import CampaignPanel from '../CampaignPanel';
import CreateNewCampaign from '../CreateNewCampaign';
import CreativePreview from '../CreativePreview';
import CustomAppTargeting from '../CustomAppTargeting';
import DisplayPreview from '../DisplayPreview';
import CreativeUploader from '../CreativeUploader';
import DayParting from '../DayParting';
import DemoTargeting from '../DemoTargeting';
import DisplayUploader from '../DisplayUploader';
import FrequencyCaps from '../FrequencyCaps';
import GeoTargeting from '../GeoTargeting';
import Inventory from '../Inventory';
import InstallTrackingCode from '../InstallTrackingCode';
import LaunchCampaign from '../LaunchCampaign';
import NameAdGroup from '../NameAdGroup';
import SelectCategories from '../SelectCategories';
import DeviceOverlay from '../DeviceOverlay';
import SelectGenres from '../SelectGenres';
import SelectDeals from '../SelectDeals';
import TargetingSegments from '../TargetingSegments';
import TrackingEvents from '../TrackingEvents';
import TrackingSetup from '../TrackingSetup';
import UploadGeo from '../UploadGeo';
import { convertArrayToObjectByKey, getMinorityData } from '../../helpers';
import { updateDeviceTypes, updateOperatingSystems } from '../../actions';
import {
  Themes,
  GoalsListThemes,
  BreadcrumbProgressesThemes,
  CurrentDayPartsCopiesThemes,
  packageCopy,
  SetupWizardSteps,
  SetupWizardStepList,
  TargetingTypeKeys,
  TargetingTypes,
} from '../../constants';
import { useIntercom } from '../../helpers/intercomWrapper';
import { useGetCampaigns } from '@apis/campaigns';
import { useGetBundles } from '@apis/bundles';
import { useGetAllowedTargeting } from '@apis/allowedTargeting';
import { useGetAudiences } from '@apis/audiences';

const PREFIX = 'SetupCampaignWizard';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  gridContainer: `${PREFIX}-gridContainer`,
  mainContent: `${PREFIX}-mainContent`,
  preview: `${PREFIX}-preview`,
  tabs: `${PREFIX}-tabs`
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },

  [`& .${classes.paper}`]: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: 19,
    minHeight: 750,
    position: 'relative',
    marginTop: -10,
  },

  [`& .${classes.gridContainer}`]: {
    position: 'relative',
  },

  [`& .${classes.mainContent}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.preview}`]: {
    top: 0,
    left: -64,
    right: 0,
    bottom: 0,
    position: 'absolute',
    overflow: 'hidden',
  },

  [`& .${classes.tabs}`]: {
    borderBottom: `1px solid #e0e0e0`,
    width: `100%`,
    position: `relative`,
  }
}));

const initialTag = { name: '', vast_tag_url: '' };

const MemoTargetingSegments = memo(TargetingSegments);


const SetupCampaignWizard = props => {
  const { user } = useUser();
  const adContext = useContext(AdvertiserContext);

  const history = useHistory();
  const { id } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { tagUser } = useLogRocket();
  const { useGet, usePost, usePatch, useDelete, useGetAll } = useAPI();
  const { update } = useIntercom();

  const { state, actions } = useStateMachine({ updateDeviceTypes, updateOperatingSystems });

  const { deviceTypes, operatingSystems } = state.data.inventoryStep.deviceOverlayModal;

  const formMethods = useForm({
    defaultValues: {
      vastTags: [initialTag],
      creatives: [],
      displays: [],
      weightedDelivery: false,
    },
    mode: 'onBlur',
  });

  const { watch, formState, setValue, setError, handleSubmit } = formMethods;

  const weightedDelivery = watch('weightedDelivery');
  const creatives = watch('creatives');
  const displays = watch('displays');

  // geo state hook
  const {
    geo,
    setGeo,
    formatGeo,
    formatGeoName,
    formatGeoForClient,
  } = useGeo();

  const { selectEntireUS, setSelectEntireUS } = useSelectEntireUS({ geo, setGeo });

  // campaign logic hook
  const {
    campaign,
    getCampaignData,
    launchCampaign,
    saveCampaignProgress
  } = useCampaigns();

  const { adCap, display, experiment, isDisplay, isIncremental } = campaign;

  const {
    adGroups,
    isAdGroup,
    currentAdGroup,
    setCurrentAdGroup,
    setIsAdGroup,
    setAdGroups,
    getAdGroups,
    getDisplays,
    saveAdGroups
  } = useAdGroups();

  const {
    handleSelectEvent,
    totalEvents,
    trackingMethod,
    selectedEvents,
    setTrackingMethod,
  } = useTracking();

  const { isLoading, setIsLoading } = useLoader(true);
  const {
    isLoading: isUploading,
    setIsLoading: setIsUploading,
  } = useLoader(false);
  const {
    isLoading: isFetching,
    setIsLoading: setIsFetching,
  } = useLoader(true);
  const { isValidBudget } = useUtil();
  const { saveProgress } = useSaveExit();
  const { updateCampaign } = useCampaigns();

  // TODO: Need to move some of the campaign state
  // into useCampaigns custom hook
  const [navSkipSaveStatus, setNavSkipSaveStatus] = useState('');
  const [step, setStep] = useState('Welcome');
  const [campaignName, setCampaignName] = useState('');
  const [campaignUrl, setCampaignUrl] = useState('');
  const [advertiserName] = useState('');
  const [amount, setAmount] = useState('');
  const [dailyBudget, setDailyBudget] = useState('0');

  const [selectedStartDate, setSelectedStartDate] = useState(
    moment(new Date()).tz("America/New_York")
  );
  const [selectedStartTime, setSelectedStartTime] = useState(
    moment(new Date()).tz("America/New_York")
  );

  const [selectedEndDate, setSelectedEndDate] = useState(
    moment(new Date()).tz("America/New_York")
  );
  const [selectedEndTime, setSelectedEndTime] = useState(
    moment(new Date()).tz("America/New_York")
  );
  const [noEndDate, setNoEndDate] = useState(true);
  const [testIncrementally, setTestIncrementally] = useState(false);
  const [bundles, setBundles] = useState([]);
  const [deals, setDeals] = useState([]);
  const [segments, setSegments] = useState([]);
  const [isCreativePreview, setIsCreativePreview] = useState(false);
  const [isDisplayPreview, setIsDisplayPreview] = useState(false);
  const [currentPreview, setCurrentPreview] = useState(null);
  const [isDayParting, setIsDayParting] = useState(false);
  const [dayParts, setDayParts] = useState([]);
  const [breadcrumbsStatus, setBreadcrumbsStatus] = useState(null);
  const [installCompleted, setInstallCompleted] = useState(false);
  const [installLater, setInstallLater] = useState(false);
  const [hasSidePanel, setHasSidePanel] = useState(false);
  const [hasAdvancedTargeting, setHasAdvancedTargeting] = useState(false);

  const [allSelectedCreatives, setAllSelectedCreatives] = useState([]);
  const [ecommerce, setEcommerce] = useState('');
  const [tier, setTier] = useState('reach');
  const [cpmRange, setCpmRange] = useState([]);
  const [inventoryOverlay, setInventoryOverlay] = useState(false);

  const [targetingData, setTargetingData] = useState({});
  const [campaignId, setCampaignId] = useState('');
  const [displayLineItemId, setDisplayLineItemId] = useState('');
  const [overlayShown, showOverlay] = useState(false);
  const [screenSize, setScreenSize] = useState('custom');

  // Storing the multiple data sources in state
  const [demoData, setDemoData] = useState({});
  const [combinedTargetingData, setCombinedTargetingData] = useState({});
  const [segmentsData, setSegmentsData] = useState({});
  const [inventoryData, setInventoryData] = useState({});
  const [adjustRecommendedBid, setAdjustRecommendedBid] = useState(false);
  const [demographicsTouched, setDemographicsTouched] = useState(false)
  const [isDefaultDemo, setIsDefaultDemo] = useState(true);
  const [isNoBidFees, setIsNoBidFees] = useState(false);
  const [isAGFreqCaps, setIsAGFreqCaps] = useState(false);
  const [agFreqCaps, setAGFreqCaps] = useState([]);
  const [customTargeting, setCustomTargeting] = useState({});
  const [isCustomTargeting, setIsCustomTargeting] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState('est');

  const [isDailyInputError, setIsDailyInputError] = useState(false);
  const [isPercentageError, setIsPercentageError] = useState(false);

  const [isDeviceOverlay, setIsDeviceOverlay] = useState(false);

  const { data: allowedTargeting } = useGetAllowedTargeting();
  const { items: allBundles } = useGetBundles();
  const { items: audiences } = useGetAudiences();
  const advertiserHasPrivateDeals = allBundles?.filter(p => p.private).length > 0;

  // Setup Wizard Page state
  const [save, setSave] = useState({});
  const [campaignStep, setCampaignStep] = useState(null);
  const { update: updateCampaignCache } = useGetCampaigns();

  // HACK: make sure formState is read before render to enable the Proxy [https://react-hook-form.com/advanced-usage#FormProviderPerformance]
  useEffect(() => {
    formState.isDirty;
    formState.isValid;
    formState.dirtyFields;
  }, [
    formState.isDirty,
    formState.isValid,
    formState.dirtyFields,
  ]);

  // Tag user okta id, name and email in Log Rocket
  useEffect(() => {
    tagUser();
  }, []);

  useEffect(() => {
    setSave({
      exit: false,
      next: null,
      step: campaignStep,
    });
  }, []);

  const showVastTag = useMemo(
    () => adContext.theme === Themes.DEFAULT || user?.is_tvsci_employee,
    [user, adContext]
  );


  const hasCompletedAdGroups = useMemo(
    () => isDisplay
      ? isUploading || !creatives.length || !displays.length
      : isUploading || !creatives.length,
    [isDisplay, isUploading, creatives, displays]
  );

  const progresses = useMemo(() => {
    return BreadcrumbProgressesThemes[adContext.theme];
  }, [adContext.theme]);

  const canSaveProfile = useMemo(() => (
    tier === 'reach'
    || tier === 'bundles' && bundles.length > 0
    || tier === 'private' && deals.length > 0
  ), [tier, bundles, deals]);

  useEffect(() => {
    setBreadcrumbsStatus({
      createCampaign: progresses[25],
      targeting: progresses[-1],
      creative: progresses[-1],
      tracking: progresses[-1],
      launch: progresses.default,
    });
  }, [progresses]);

  useEffect(() => {
    setCampaignStep(step);
    update({
      customAttributes: {
        current_campaign_step: step,
        current_campaign_name: campaignName,
        current_campaign_id: campaignId
      }
    });
  }, [step, campaignName, campaignName]);

  useEffect(() => {
    setCombinedTargetingData({
      ...demoData,
      ...inventoryData,
      ...segmentsData,
      ...targetingData,
      ...customTargeting,
      dayparting: dayParts,
      geo: formatGeo(geo),
      timezone: deliveryTime,
    });
  }, [demoData, inventoryData, targetingData, geo, segmentsData, dayParts, customTargeting, deliveryTime]);

  useEffect(() => {
    if (amount !== '') {
      handleDisplayDailyBudget(amount);
    }
  }, [amount]);

  useEffect(() => {
    if (
      currentAdGroup != null &&
      ['TrackingSetup', 'LaunchCampaign'].includes(step)
    ) {
      handleSaveAdGroup();
      getBundles()
    }
  }, [currentAdGroup]);

  useEffect(() => {
    if (step === SetupWizardSteps['CREATIVE_UPLOAD']) {
      if (creatives.length > 0) {
        handleBreadcrumbsStatus('creative', 50, 'targeting', 100);
      } else {
        handleBreadcrumbsStatus('creative', 0, 'targeting', 100);
      }
    }
  }, [creatives, step]);

  useEffect(() => {
    handleCampaignProgress();
  }, [save, campaignId]);

  useEffect(() => {
    if (adContext && adContext.id != null) {
      setIsLoading(false);
      getDraftCampaign();
      getBundles();
    }
  }, [adContext.id]);

  useEffect(() => {
    if (currentAdGroup) {
      loadCreatives(currentAdGroup);
    }
  }, [currentAdGroup]);

  useEffect(() => {
    const creativesWithTagUrl = creatives.filter(i => i.vast_tag_url);
    const vastTags = creativesWithTagUrl.length > 0 ? creativesWithTagUrl : [initialTag];

    setValue('vastTags', vastTags);
  }, [creatives]);

  useEffect(() => {
    if (currentAdGroup) {
      setValue('weightedDelivery', currentAdGroup.creative_weighting_method === 'WEIGHTED');
    }
  }, [currentAdGroup]);

  useEffect(() => {
    setIsDailyInputError(false);
    setIsPercentageError(false);

    if (isDisplay) {
      const isValid = isValidBudget(display.displayBudget);

      if (isValid) {
        closeSnackbar();
        setIsDailyInputError(false);
        setIsPercentageError(false);
      }

      if (!isValid) {
        setIsDailyInputError(true);
        setIsPercentageError(true);

        if (!isFetching) {
          enqueueSnackbar(
            'Display ad groups must have a daily budget over one cent. Please increase your daily budget or retarget percentage.',
            {
              anchorOrigin: {
                horizontal: 'center',
                vertical: 'bottom',
              },
              autoHideDuration: null,
              preventDuplicate: true,
              variant: 'warning',
            }
          );
        }
      }
    }
  }, [display.displayBudget, isDisplay]);

  useEffect(() => {
    if (step !== 'LaunchCampaign') {
      return;
    }

    const creativeRequests = adGroups.map(f => f.creatives.map(c => getCreativeData(c)));

    Promise.all(creativeRequests.flat())
      .then(responses => setAllSelectedCreatives(responses))
      .catch(error => console.error(error));
  }, [step, adGroups]);

  // Function handlers for collecting
  // campaign/lineitem data
  async function getDraftCampaign() {
    if (id && adContext.id) {
      setIsLoading(true);
      setIsFetching(true);
      setCampaignId(id);

      const campaignData = await getCampaignData(id);

      if (campaignData.name && campaignData.start_date) {
        setCampaignName(campaignData.name);
        setCampaignUrl(campaignData.url);
        setAmount(campaignData.daily_budget);

        handleUpdateStartDate(moment(campaignData.start_date).isValid() ? moment.tz(campaignData.start_date, 'America/New_York') : null);
        handleBreadcrumbsStatus('createCampaign', 100, 'targeting', 0);

        // Handles end dates and input toggles from draft campaign
        if (campaignData && campaignData.end_date != null) {
          setNoEndDate(false);
          setSelectedEndDate(moment(campaignData.end_date).isValid() ? moment.tz(campaignData.end_date, 'America/New_York') : null);
          setSelectedEndTime(moment(campaignData.end_date).isValid() ? moment.tz(campaignData.end_date, 'America/New_York') : null);
        } else {
          setNoEndDate(true);
        }

        if (campaignData.freq_caps && campaignData.freq_caps.length > 0) {
          adCap.setFreqCaps(campaignData.freq_caps);
        }

        if (campaignData.experiment_plan) {
          campaign.handleExperiment(true);
          campaign.experiment.setPlan(campaignData.experiment_plan);
        }

        // Get draft lineitems and static displays
        await Promise.all([
          getDraftDisplays(campaignData.id),
          getDraftLineitems(campaignData.id, campaignData.progress),
        ]);

        handleCampaignPanel(true);
        setIsLoading(false);

        if (
          [
            'GeoTargeting',
            'DemoTargeting',
            'Inventory',
            'TargetingSegments',
            'SelectCategories',
            'SelectDeals',
            'Genres',
            'Private',
            'Bundles',
            'NameAdGroup',
            'CreativeUpload',
            'DisplayUpload',
          ].includes(campaignData.progress)
        ) {
          setIsAdGroup(true);
          handleCampaignPanel(true);
        } else {
          setIsAdGroup(false);
          handleCampaignPanel(false);
        }

        setIsFetching(false);
        setStep(campaignData.progress);
        return campaignData;
      }
    }
  }

  async function getDraftLineitems(campaignId, campaignStep) {
    try {
      const finishSteps = ['LaunchCampaign', 'TrackingSetup'].includes(campaignStep);
      const draftAdGroups = await getAdGroups(campaignId);

      if (!draftAdGroups) {
        return;
      }

      if (draftAdGroups.length > 0 && finishSteps) {
        setAdGroups(draftAdGroups);
      }

      if (draftAdGroups.length === 1 && !finishSteps) {
        setAdGroups([]);
      }

      if (draftAdGroups.length > 1 && !finishSteps) {
        setAdGroups([...draftAdGroups.slice(0, -1)]);
      }

      const lastAdGroup = draftAdGroups[draftAdGroups.length - 1];

      if (lastAdGroup) {
        handleLineItemData(lastAdGroup, campaignStep);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getDraftDisplays(campaignId) {
    try {
      const draftDisplays = await getDisplays(campaignId);

      if (draftDisplays[0] && draftDisplays[0].creatives) {
        const displayRequests = draftDisplays[0].creatives
          .map(c => getDisplayCreativeData(c));

        setDisplayLineItemId(draftDisplays[0].id);

        return Promise.all(displayRequests)
          .then(res => {
            if (res && res.length > 0) {
              setValue('displays', res);
            }

            return res;
          })
          .catch(err => {
            console.log(err);
            return err;
          });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getDisplayCreativeData(dataUrl) {
    if (dataUrl) {
      return useGet(dataUrl)
        .then(res => res)
        .catch(err => err);
    }
  }

  function getCreativeData(dataUrl) {
    if (dataUrl) {
      return useGet(dataUrl)
        .then(res => res)
        .catch(err => err);
    }
  }

  const experimentStatus = useMemo(() => {
    if (campaign.isExperiment) {
      const total = parseInt(experiment.plan.substring(0, 1), 10);

      return {
        copy: `Save & Create Ad Group ${adGroups.length + 1} of ${total}`,
        completed: total - adGroups.length === 1,
      };
    }

    return ({
      copy: 'Save & Create A New Ad Group',
      completed: false,
    });
  }, [campaign.isExperiment, experiment.plan, adGroups]);

  const handleCampaignProgress = async () => {
    const res = await saveCampaignProgress(save, campaignId)
    if (res && save.exit) {
      if (
        [
          'TrackingSetup',
          'InstallTrackingCode',
          'TrackingEvents',
          'ConfigureTrackingEvents',
          'Inventory',
        ].includes(save.step)
      ) {
        history.push('/');
      }
    }
  };

  const handleNoBidFees = segs => {
    if (audiences && audiences.length > 0) {
      const selected = segs.reduce((acc, curr) => {
        const audience = audiences.find(a => a.audience_name === curr);

        if (audience) {
          return [...acc, audience];
        }

        return acc;
      }, []);

      setIsNoBidFees(selected.some(a => a.no_fee))
    }
  };

  const handleDefaultDemo = (gender, age, income) => {
    let isDefault = true;

    if (gender && gender.toLowerCase() !== 'all') {
      isDefault = false;
    }

    if (age && age[0] !== 2 || age[1] !== 65) {
      isDefault = false;
    }

    if (income && income[0] !== 30 || income[1] !== 250) {
      isDefault = false;
    }

    setIsDefaultDemo(isDefault);
  };

  const handleProgressData = async (targeting, targetingGeo, lineitem) => {
    // Handle Geo data
    if (targetingGeo != null) {
      setGeo(targetingGeo);

      handleBreadcrumbsStatus('targeting', 25);
    }

    // Handle Demo data
    if (targeting.advanced != null) {
      setHasAdvancedTargeting(targeting.advanced);
    }

    if (targeting.age && targeting.income && targeting.gender) {
      handleDemoData({
        advanced: targeting.advanced,
        age: targeting.age,
        income: targeting.income,
        gender: targeting.gender,
      });
      handleDefaultDemo(targeting.gender, targeting.age, targeting.income);
      handleBreadcrumbsStatus('targeting', 50);
    }

    if (
      targeting.advanced &&
      targeting.segments &&
      targeting.segments.length > 0
    ) {
      handleNoBidFees(targeting.segments);
      setSegments(targeting.segments);
    }

    // Handle Inventory data
    if (targeting.inventory) {
      if (
        targeting.inventory === 'premium-reach' ||
        (targeting.inventory === 'bundles' &&
          targeting.bundles.includes('Run Of Network'))
      ) {
        setTier('reach');
      } else {
        setTier(targeting.inventory);
      }

      handleBreadcrumbsStatus('targeting', 75);
    }

    if (targeting.screen_size != null) {
      setScreenSize(targeting.screen_size);
    }

    if (targeting.small_screen && targeting.small_screen != null) {
      setScreenSize('both');
    }

    setCustomTargeting({
      app_name: targeting.app_name,
      app_id_list: targeting.app_id_list,
      app_bundle_list: targeting.app_bundle_list,
      publisher_id: targeting.publisher_id,
    });

    if (targeting.bundles && targeting.bundles.length > 0) {
      if (targeting.inventory === 'bundles') {
        setBundles(targeting.bundles);
      }

      if (targeting.inventory === 'private') {
        setDeals(targeting.bundles);
      }
    }

    if (lineitem.freq_caps && lineitem.freq_caps.length) {
      setAGFreqCaps(lineitem.freq_caps);
    }

    if (targeting.timezone) {
      setDeliveryTime(targeting.timezone);
    }

    // Handle Creatives data
    if (lineitem && lineitem.creatives && lineitem.creatives.length > 0) {
      handleBreadcrumbsStatus('targeting', 100);

      if (isDisplay) {
        handleBreadcrumbsStatus('creative', 50);
      } else {
        handleBreadcrumbsStatus('creative', 100);
      }

      const creativesRequests = lineitem.creatives.map(c =>
        getCreativeData(c)
      );

      return Promise.all(creativesRequests)
        .then(responses => {
          if (responses && responses.length > 0) {
            setValue('creatives', responses);
          }

          return responses;
        })
        .catch(error => { console.error('Error getting creatives', error) });
    }
  };

  const handleLineItemData = (data, initStep) => {
    const { targeting } = data;
    const targetingGeo = formatGeoForClient(targeting.geo);

    console.log('targeting data', targeting);

    handleCurrentAdGroup({ ...data, targeting });

    if (['LaunchCampaign', 'TrackingSetup'].includes(initStep)) {
      handleBreadcrumbsStatus('creative', 100);
      handleBreadcrumbsStatus('targeting', 100);

      if (initStep === 'TrackingSetup') {
        handleBreadcrumbsStatus('tracking', 0);
      }

      if (initStep === 'LaunchCampaign') {
        handleBreadcrumbsStatus('tracking', 100, 'launch', 0);
        handleBreadcrumbsStatus('launch', 0);
      }
    }

    if (targeting && targeting.dayparting) {
      setDayParts(targeting.dayparting);
    }

    return handleProgressData(targeting, targetingGeo, data);
  };

  const handleDisplayDailyBudget = dayBudget => {
    const budget =
      (parseFloat(dayBudget, 10) * parseFloat(display.percent, 10)) / 100;

    if (!isNaN(budget)) {
      if (budget < .01) {
        display.setDisplayBudget(0);
      } else {
        display.setDisplayBudget((Math.floor(budget * 100) / 100).toFixed(2));
      }
    }
  };

  const handleUpdateAmount = newAmount => {
    if (newAmount.length) {
      // Makes sure the amount is at least 0
      if (newAmount >= 0) {
        // To make sure there are only two decimal places,
        // first check for a decimal
        if (newAmount.indexOf('.') > -1) {
          const decimalIndex = newAmount.indexOf('.');
          // Make a string from the part up to
          // and including the decimal place
          const preDecimals = newAmount.substr(0, decimalIndex + 1);
          // Make a string from everything after the decimal place
          const decimals = newAmount.substr(decimalIndex + 1);
          // Remove everything after the first two characters
          // of the second string
          const shavedDecimals = decimals.substring(0, 2);
          // Put strings back together
          const newAmountTwoDecimals = preDecimals + shavedDecimals;

          setAmount(newAmountTwoDecimals);
        } else {
          setAmount(newAmount);
        }
      }
    } else {
      // Sets the 'amount' field to blank if there's no amount
      setAmount('');
    }
  };

  const handleDailyBudget = newAmount => {
    if (newAmount.length) {
      // Makes sure the amount is at least 0
      if (newAmount >= 0) {
        // To make sure there are only two decimal places,
        // first check for a decimal
        if (newAmount.indexOf('.') > -1) {
          const decimalIndex = newAmount.indexOf('.');
          // Make a string from the part up to and
          // including the decimal place
          const preDecimals = newAmount.substr(0, decimalIndex + 1);
          // Make a string from everything after the decimal place
          const decimals = newAmount.substr(decimalIndex + 1);
          // Remove everything after the first two characters
          // of the second string
          const shavedDecimals = decimals.substring(0, 2);
          // Put strings back together
          const newAmountTwoDecimals = preDecimals + shavedDecimals;

          console.log(dailyBudget);

          setDailyBudget(newAmountTwoDecimals);
        } else {
          setDailyBudget(newAmount);
        }
      }
    } else {
      // Sets the 'amount' field to blank if there's no amount
      setAmount('');
    }
  };

  async function getBundles() {
    const cpmArr = []
    if (bundles && bundles.length > 0) {
      return useGetAll('/bundles', [], total => {
        if (total && total.length > 0) {
          const bundlesCPM = _.flatten(_.map(bundles, (bundle) => (
            [...total].filter(i => i.bundle_name === bundle)
          )))
          _.map(bundlesCPM, (bundle) => (
            cpmArr.push(Number(bundle.estimated_cpm))
          ))
          cpmArr.sort((a, b) => a - b)
          setCpmRange([cpmArr[0], cpmArr.slice(-1)[0]]);
        }
      })
    }
    return setCpmRange([12, 24]);
  }

  useEffect(() => {
    getBundles();
  }, [bundles, step, demographicsTouched, breadcrumbsStatus]);

  useEffect(() => {
    if (!allowedTargeting) {
      return;
    }

    const { targeting } = currentAdGroup || {};
    const targetingData = typeof targeting === 'string' ? JSON.parse(targeting) : targeting || {};
    const list = getTargetingList(targetingData, allowedTargeting);

    actions.updateDeviceTypes(list[TargetingTypes.DEVICE]);
    actions.updateOperatingSystems(list[TargetingTypes.OS]);
  }, [allowedTargeting, currentAdGroup && currentAdGroup.targeting]);

  useEffect(() => {
    saveProgress(
      save,
      step === 'CreativeUpload' ? 'CreativeUpload' : 'DisplayUpload',
      step === 'CreativeUpload' ? handleCreativeSave : handleDisplaySave,
      handleTriggerSave
    );
  }, [save]);

  useEffect(() => {
    const status = step === 'Welcome' ? 'back' : 'saveAndExit';

    setNavSkipSaveStatus(status);
  }, [step]);

  const handleIsAdGroup = value => {
    setIsAdGroup(value);
  };

  const handleCampaignPanel = isVisible => {
    setHasSidePanel(isVisible);
  };

  const handleUpdateStartDate = date => {
    if (!date) {
      date = '';
    }
    setSelectedStartDate(date);
  };

  const handleUpdateStartTime = date => {
    if (!date) {
      date = '';
    }
    setSelectedStartTime(date);
  };

  const handleUpdateEndDate = date => {
    if (!date) {
      date = '';
    }
    setSelectedEndDate(date);
  };

  const handleUpdateEndTime = date => {
    if (!date) {
      date = '';
    }
    setSelectedEndTime(date);
  };

  const handleNoEndDateChange = () => {
    setNoEndDate(!noEndDate);
  };

  const enableDateTimeInput = () => {
    if (noEndDate) {
      handleNoEndDateChange(new Date(moment().format('YYYY/MM/DD HH:mm:ss')));
    }
  };

  const handleTestIncrementally = () => {
    setTestIncrementally(!testIncrementally);
  };

  const handleDayParts = value => {
    setDayParts(value);
  };

  const handleClosePreview = () => {
    setCurrentPreview(null);
    setIsCreativePreview(false);
    setIsDisplayPreview(false);
  };

  const setFieldErrors = (data) => {
    const errors = Object.entries(data);

    errors.forEach(([field, messages]) => {
      messages.forEach((message) => {
        setError(
          `${field}.${id}`,
          { type: 'custom', message }
        );
      });
    });
  };

  const getCreativeAdGroups = adGroupId => useGet('/creative_lineitems', {
    params: {
      line_item_id: adGroupId,
    },
  });

  const getCreatives = (urls) => {
    const creativesPromises = urls.map(url => useGet(url));

    return Promise.all(creativesPromises);
  };

  const postCreativeAdGroup = data => usePost('/creative_lineitems/', data);

  const patchCreativeAdGroup = (id, data) => usePatch(`/creative_lineitems/${id}`, data);

  const patchCreative = (id, creative) => usePatch(`/creatives/${id}`, creative);

  const loadCreatives = async ({ id, creatives: creativeUrls = [] }) => {
    const { results: creativeAdGroups } = await getCreativeAdGroups(id);
    const creatives = await getCreatives(creativeUrls);
    const creativeAdGroupsByCreativeUrl = convertArrayToObjectByKey(creativeAdGroups, 'creative');

    const nextCreatives = creatives.map((creative) => {
      const { weighting } = creativeAdGroupsByCreativeUrl[creative.url] || {};

      return ({
        ...creative,
        weighting,
      });
    });

    setValue('creatives', nextCreatives, { shouldDirty: false, shouldTouch: false });
  };

  const saveCreativeAdGroup = ({ creativeAdGroupId, weighting, url }) => creativeAdGroupId
    ? patchCreativeAdGroup(creativeAdGroupId, { weighting })
    : postCreativeAdGroup({
      lineitem: currentAdGroup.url,
      creative: url,
      weighting
    });

  const saveCreatives = async ({ weightedDelivery, creatives }) => {
    const { results: creativeAdGroups } = await getCreativeAdGroups(currentAdGroup.id);
    const creativeAdGroupsByCreativeUrl = convertArrayToObjectByKey(creativeAdGroups, 'creative');

    const requests = creatives.map(async ({ id, weighting, url, ...creative }, index) => {
      const dirtyRowFields = formState.dirtyFields.creatives[index] || {};
      const isWeightingDirty = weightedDelivery && dirtyRowFields.weighting;
      const { id: creativeAdGroupId } = creativeAdGroupsByCreativeUrl[url] || {};

      const { data: nextCreativeAdGroup = {} } = isWeightingDirty
        ? await saveCreativeAdGroup({ creativeAdGroupId, weighting, url })
        : {};
      const { data: nextCreative } = await patchCreative(id, creative);

      return {
        ...creative,
        ...nextCreative,
        weighting: nextCreativeAdGroup.weighting,
      };
    });

    return Promise.all(requests);
  };

  const saveDisplays = async (data) => {
    const saveDisplayRequests = data.map(display => usePatch(`/static_display_creatives/${display.id}`, display));
    const savedDisplays = await Promise.all(saveDisplayRequests);

    return savedDisplays.map(display => display.data);
  };

  const handleDisplaySave = async () => {
    setIsLoading(true);

    const startDateTime = formatDateTime(
      selectedStartDate,
      selectedStartTime
    );

    let endDateTime = null;

    // Set end date and time if exists
    if (!noEndDate) {
      endDateTime = formatDateTime(selectedEndDate, selectedEndTime);
    }

    const staticDisplayUrls = displays.map(d => d.url);

    const dataObj = {
      active: false,
      campaign: campaignUrl,
      creatives: staticDisplayUrls,
      draft: true,
      daily_budget: display.displayBudget,
      end_date: endDateTime,
      name: 'Ad Exposure Retargeting - Display',
      start_date: startDateTime,
    };

    try {
      if (formState.dirtyFields.displays) {
        const savedDisplays = await saveDisplays(displays);

        setValue('displays', savedDisplays);
      }

      /***
      // If a displayLineItem WAS already created
      ***/
      if (displayLineItemId && displayLineItemId !== '') {
        const draftDisplay = await usePatch(
          `/static_display_lineitems/${displayLineItemId}`,
          dataObj
        );

        // Handle Save & Exit button click
        if (save && save.step && save.step === 'DisplayUpload') {
          if (save.exit) {
            history.push('/');
          }
        }

        if (save && save.next && save.next === 'GeoTargeting') {
          if (!save.exit) {
            handleAddAdGroup();
          }
        }

        // Handle save and continue button click
        if (!save.exit && save.next && save.next === 'TrackingSetup') {
          setStep(save.next);
          handleCampaignPanel(false);
          handleSaveAdGroup();
          handleBreadcrumbsStatus('creative', 100, 'tracking', 0);
          handleBreadcrumbsStatus('targeting', 100);
          setIsAdGroup(false);
        }

        return draftDisplay;
      }

      /***
      // If a displayLineItem has NOT yet been created
      ***/
      const response = await usePost('/static_display_lineitems', dataObj);
      console.log('Response from display lineitems', response);

      if (response && response.data && response.data.id) {
        setDisplayLineItemId(response.data.id);
      }

      // Handle Save & Exit button click
      if (save && save.step && save.step === 'DisplayUpload') {
        if (save.exit) {
          history.push('/');
        }
      }

      if (save && save.next && save.next === 'GeoTargeting') {
        if (!save.exit) {
          handleAddAdGroup();
        }
      }

      // Handle save and continue button click
      if (!save.exit && save.next && save.next === 'TrackingSetup') {
        setStep(save.next);
        handleCampaignPanel(false);
        handleSaveAdGroup();
        handleBreadcrumbsStatus('creative', 100, 'tracking', 0);
        handleBreadcrumbsStatus('targeting', 100);
        setIsAdGroup(false);
      }

      return response;
    } catch (error) {
      console.error('Error in saving DisplayUpload', error);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreativeSave = async () => {
    setIsLoading(true);

    const creativeUrls = creatives.map(c => c.url);

    const data = {
      creative_weighting_method: weightedDelivery ? 'WEIGHTED' : 'RANDOM',
      creatives: creativeUrls,
      targeting: JSON.stringify({
        ...combinedTargetingData,
      })
    };

    try {
      const response = await usePatch(`/lineitems/${currentAdGroup.id}/`, data);

      if (formState.dirtyFields.creatives) {
        const savedCreatives = await saveCreatives({
          weightedDelivery,
          creatives,
        });

        setValue('creatives', savedCreatives);
      }

      // Handle Save & Exit button click
      if (save.exit) {
        history.push('/');
      }

      // Handle next button click
      if (!save.exit && save.next && save.next === 'TrackingSetup') {
        setStep(save.next);
        handleCampaignPanel(false);
        handleSaveAdGroup();
        handleBreadcrumbsStatus('creative', 100, 'tracking', 0);
        handleBreadcrumbsStatus('targeting', 100);
        setIsAdGroup(false);
      }

      setCurrentAdGroup(response.data);

      return response;
    } catch (error) {
      if (error.response) {
        setFieldErrors(error.response.data);
      }

      console.error('Error in saving CreativeUpload in CampaignPanel', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreativeUploaderSubmit = async ({ weightedDelivery, creatives }) => {
    setIsLoading(true);

    const data = {
      creative_weighting_method: weightedDelivery ? 'WEIGHTED' : 'RANDOM',
      creatives: creatives.map(c => c.url),
    };

    try {
      const response = await usePatch(`/lineitems/${currentAdGroup.id}/`, data);

      if (formState.dirtyFields.creatives) {
        const savedCreatives = await saveCreatives({ weightedDelivery, creatives });

        setValue('creatives', savedCreatives);
      }

      if (isDisplay) {
        setStep('DisplayUpload');
      } else {
        handleAddAdGroup();
      }

      return response;
    } catch (error) {
      if (error.response) {
        setFieldErrors(error.response.data);
      }

      console.error(error);

      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisplayUploaderSubmit = () => {
    setIsAdGroup(true);

    handleTriggerSave('DisplayUpload', false, 'GeoTargeting');
  };

  const handleCampaignPanelSubmit = async () => {
    setIsLoading(true);

    try {
      if (step === 'DisplayUpload' || step === 'CreativeUpload') {
        handleTriggerSave(step, false, 'TrackingSetup');
      }
    } catch (error) {
      if (error.response) {
        setFieldErrors(error.response.data);
      }

      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setGeoResults = results => {
    setGeo(results);
  };

  const handleFinalizeCampaignData = async ({
    active,
    payment_profile,
    nbcu_purchase_order
  }) => {
    const data = {
      active,
      campaignId,
      daily_budget: amount,
      endDate: selectedEndDate,
      endTime: selectedEndTime,
      name: campaignName,
      noEndDate,
      payment_profile,
      nbcu_purchase_order,
      startDate: selectedStartDate,
      startTime: selectedStartTime,
    };

    return await launchCampaign(data);
  };

  const handleDemoData = data => {
    if (data) {
      setDemoData(data);
    }
  };

  const handleSegmentsData = data => {
    if (data) {
      setSegmentsData(data);
    }
  };

  const handleInventoryData = data => {
    if (data) {
      setInventoryData(data);
    }
  };

  const handleAssignCreatives = media => {
    const creatives = watch('creatives');

    const [prevCreatives, uploadableCreatives] = creatives.reduce((accumulation, creative) => {
      const index = creative.uploadable ? 1 : 0;

      accumulation[index].push(creative);

      return accumulation;
    }, [[], []]);

    const nextCreatives = media.map((creative, index) => {
      const { click_url, weighting } = uploadableCreatives[index] || creative;

      return {
        ...creative,
        click_url,
        weighting,
      };
    });

    setValue('creatives', [...prevCreatives, ...nextCreatives]);
  };

  const handleUnassignCreative = media => {
    setValue('creatives', creatives.filter(creative => creative.id !== media.id));
  };

  const handleAddAdGroup = () => {
    setIsLoading(true);

    if (breadcrumbsStatus['creative'] >= 0) {
      handleBreadcrumbsStatus('targeting', 0)
    } else {
      handleBreadcrumbsStatus('targeting', 0, 'creative', -1);
    }

    handleSaveAdGroup();
    setValue('creatives', []);

    return usePost('/lineitems', {
      campaign: campaignUrl,
      creatives: [],
      daily_budget: 0,
      draft: true,
    })
      .then(res => {
        console.log('Res from create a new adgroup', res);
        setStep('GeoTargeting');
        setCurrentAdGroup(res.data);
        setIsLoading(false);
        return res;
      })
      .catch(err => {
        setIsLoading(false);
        console.error('Error saving new adGroup', err);
      });
  };

  const handleInventorySave = async () => {
    setIsLoading(true);
    const inv = tier !== 'reach' ? tier : 'premium-reach';

    const { cpm } = packageCopy[adContext.theme][tier];

    const deviceTypeMinorityData = getMinorityData(deviceTypes);
    const operatingSystemMinorityData = getMinorityData(operatingSystems);

    if (cpm) {
      const cpmStart = cpm[0] * 1.18 + 0.4;
      const cpmEnd = cpm[1] * 1.18 + 0.4;

      setCpmRange([cpmStart, cpmEnd]);
    }

    const dataObj = {
      inventory: inv,
      screen_size: screenSize,
      device: {
        blacklist: !deviceTypeMinorityData.isSelectedMinority,
        ids: deviceTypeMinorityData.data.map(item => item.id)
      },
      os: {
        blacklist: !operatingSystemMinorityData.isSelectedMinority,
        ids: operatingSystemMinorityData.data.map(item => item.id)
      },
    };

    if (tier === 'bundles') {
      dataObj.inventory = 'bundles';
      dataObj.bundles = bundles;
    }

    if (tier === 'reach' && adContext.theme === Themes.NBCU) {
      dataObj.inventory = 'bundles';
      dataObj.bundles = ['Run Of Network'];
    }

    handleInventoryData(dataObj);

    const data = {
      targeting: JSON.stringify({
        ...combinedTargetingData,
        ...dataObj,
      }),
    };

    try {
      await usePatch(`/lineitems/${currentAdGroup.id}/`, data);

      // Handle Save & Exit button click
      if (save && save.step && save.step === 'Inventory') {
        if (save.exit) {
          history.push('/');
        }
      }

      // Handle next button click
      if (!save.exit) {
        setStep('NameAdGroup');
      }

      setIsLoading(false);

      return handleTriggerSave('Inventory', false, 'NameAdGroup');
    } catch (error) {
      console.error('Error in saving Inventory', error);

      setIsLoading(false);

      return error;
    }
  };

  const handleRemoveCurrentAdGroup = async () => {
    const lastAdGroup = adGroups[adGroups.length - 1];
    const restAdGroups = adGroups.filter(adGroup => adGroup !== lastAdGroup);

    if (!lastAdGroup) {
      return;
    }

    try {
      await usePatch(`/lineitems/${currentAdGroup.id}/`, {
        creatives: [],
      });
      await useDelete(`/lineitems/${currentAdGroup.id}/`);

      lastAdGroup.creatives.forEach(creative => {
        handleUnassignCreative(creative);
      });

      handleLineItemData(lastAdGroup, step);
      setAdGroups(restAdGroups);
      setStep('CreativeUpload');
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveLastAdGroup = () => {
    const adgs = [...adGroups];
    let removed = adgs.splice(adGroups.length - 1, 1);
    setAdGroups(adgs);

    if (removed && Array.isArray(removed)) {
      removed = removed[0];
    }

    return removed;
  };

  // Stores current ad group to memory
  const handleCurrentAdGroup = group => {
    const { targeting } = group;

    const targetingObj = {
      ...targeting,
      ...demoData,
      ...targetingData,
      ...inventoryData,
      dayparting: dayParts,
    };

    if (targeting) {
      if (targeting.geo) {
        targetingObj.geo = targeting.geo;
      } else {
        targetingObj.geo = geo;
      }

      if (targeting.inventory) {
        targetingObj.inventory = targeting.inventory;
      }

      if (targeting.bundles) {
        targetingObj.bundles = targeting.bundles;
      }
    }

    const adGroupData = {
      ...group,
      targeting: targetingObj,
    };

    setCurrentAdGroup(adGroupData);
  };

  const handleEditAdGroup = group => {
    setIsAdGroup(true);
    setCurrentAdGroup(group);

    const { targeting } = group;
    let parsedTargeting = targeting;

    if (typeof targeting === 'string') {
      parsedTargeting = JSON.parse(targeting);
    }

    const targetingGeo = formatGeoForClient(parsedTargeting.geo);

    setValue('creatives', creatives.filter(creative => !group.creatives.includes(creative)));

    return handleProgressData(parsedTargeting, targetingGeo, group);
  };

  // Adds current ad group to adGroups state array
  const handleSaveAdGroup = () => {
    if (currentAdGroup != null) {
      const creativeUrls = creatives.map(c => c.url);
      const nextTargeting = typeof currentAdGroup.targeting === 'string' ? JSON.parse(currentAdGroup.targeting) : currentAdGroup.targeting;

      setAdGroups(prev => [
        ...prev.filter(item => item.id !== currentAdGroup.id),
        {
          ...currentAdGroup,
          creatives: creativeUrls,
          targeting: {
            ...combinedTargetingData,
            ...nextTargeting,
          },
        },
      ]);

      handleResetAdGroup();
    }
  };

  const handleAdGroupsData = ({ campaign }) => {
    const dataObj = {
      amount,
      isDisplay,
      display,
      url: campaign,
      isIncremental,
    };

    return saveAdGroups(adGroups, dataObj);
  };

  const handleSaveCampaign = async () => {
    const adUrl = adContext.url;

    const startDateTime = moment.tz(
      `${moment.tz(selectedStartDate, 'America/New_York').format('YYYY-MM-DD')} ${moment.tz(selectedStartTime, 'America/New_York').format('HH:mm')}`,
      "America/New_York"
    );

    const utcStartDateTime = startDateTime.utc().format();

    let utcEndDateTime = null;
    if (!noEndDate) {
      const endDateTime = moment.tz(
        `${moment(selectedEndDate).format('YYYY-MM-DD')} ${moment(selectedEndTime).format('HH:mm')}`,
        "America/New_York"
      );
      utcEndDateTime = endDateTime.utc().format();
    }

    // Set up the data
    const dataObj = {
      active: false,
      advertiser: adUrl,
      daily_budget: amount,
      day_parts: dayParts,
      display_pct: null,
      draft: true,
      end_date: utcEndDateTime,
      experiment_type: null,
      experiment_plan: null,
      goal_amt: null,
      goal_type: null,
      name: campaignName,
      progress: step,
      start_date: utcStartDateTime,
    };

    const { adCap, display, incremental, goals } = campaign;

    // Check toggled inputs and
    // update data if exists
    if (adCap.freqCaps.length > 0) {
      dataObj.freq_caps = adCap.freqCaps
    }

    if (campaign.isIncremental) {
      dataObj.experiment_type = incremental.holdOut;
    }

    if (campaign.isGoals) {
      dataObj.goal_amt = goals.cpa;
      dataObj.goal_type = goals.optimize;
    }

    if (campaign.isDisplay) {
      dataObj.display_pct = display.percent;
    }

    if (campaign.isExperiment) {
      dataObj.experiment_plan = experiment.plan;
    }

    try {
      if (campaignId && campaignId !== '') {
        return await updateCampaign(campaignId, dataObj)
      }

      const response = await usePost('/campaigns/', dataObj);

      if (
        response &&
        response.data &&
        response.data.url &&
        response.status &&
        (response.status === 200 || response.status === 201)
      ) {
        await updateCampaignCache(response.data, true);
        // TODO: remove campaign url from localStorage
        localStorage.setItem('campaignUrl', response.data.url);

        setCampaignUrl(response.data.url);
        setCampaignId(response.data.id);

        // Day Parting lives in the adGroup
        // so must create an adGroup if dayParting exists
        if (!currentAdGroup) {
          const lineitemData = {
            campaign: response.data.url,
            creatives: [],
            daily_budget: 0,
            draft: true,
            targeting: JSON.stringify({
              dayparting: dayParts,
            }),
          };

          const newLineitem = await usePost('/lineitems', lineitemData);

          if (newLineitem) {
            setCurrentAdGroup(newLineitem.data);
          }
        }
      }

      return response;
    } catch (error) {
      console.error('Error in creating new Campaign', error);
      return error;
    }
  };

  const handleDisplaysData = ({ campaign }) => {
    if (displays && displays.length > 0) {
      const startDateTime = moment.tz(
        `${moment(selectedStartDate).format('YYYY-MM-DD')} ${moment(selectedStartTime).format('HH:mm')}`,
        "America/New_York"
      );

      const utcStartDateTime = startDateTime.utc().format();

      let utcEndDateTime = null;
      if (!noEndDate) {
        const endDateTime = moment.tz(
          `${moment(selectedEndDate).format('YYYY-MM-DD')} ${moment(selectedEndTime).format('HH:mm')}`,
          "America/New_York"
        );
        utcEndDateTime = endDateTime.utc().format();
      }

      const staticDisplayUrls = displays.map(d => d.url);

      const dataObj = {
        active: true,
        campaign,
        creatives: staticDisplayUrls,
        daily_budget: display.displayBudget,
        draft: false,
        end_date: utcEndDateTime,
        is_control_group: false,
        name: 'Ad Exposure Retargeting - Display',
        start_date: utcStartDateTime,
      };

      return usePatch(
        `/static_display_lineitems/${displayLineItemId}`,
        dataObj
      )
        .then(res => {
          console.log('Respone from /static_display_lineitems', res);
          return res;
        })
        .catch(error => {
          console.error(error);
          return error;
        });
    }
  };
  ///////////////////////////////////////////////////////
  // END DATA HANDLERS
  ///////////////////////////////////////////////////////

  const handleResetAdGroup = () => {
    // Set states to Default
    // for new Ad Group
    setHasAdvancedTargeting(false);
    setCurrentAdGroup(null);
    setDemoData({});
    // TODO: figure out why passing the 'prev' param
    // fixes the reset of geoResults
    setGeoResults(prev => []); // eslint-disable-line no-unused-vars
    setTargetingData({});
    setInventoryData({});
    setCombinedTargetingData({});
    setTier('reach');
    setBundles([]);
    setScreenSize('both');
    setAdjustRecommendedBid(false);
    setDemographicsTouched(false);
    setIsDefaultDemo(true);
    setSegments([]);
    setSegmentsData([]);
    setAGFreqCaps([]);
    setDayParts([]);
    setCustomTargeting({});
  };

  // Determines if the user will see the behaviors & interests view
  const handleHasAdvancedTargeting = newValue => {
    setHasAdvancedTargeting(newValue);
  };

  const handleInstallCompleted = value => {
    if (value) {
      setInstallLater(false);
    }

    setInstallCompleted(value);
  };

  const handleInstallLater = value => {
    if (value) {
      setInstallCompleted(false);
    }

    setInstallLater(value);
  };

  // Updates breadcrumbs progress and styling
  const handleBreadcrumbsStatus = (
    firstKey,
    firstValue,
    secondKey,
    secondValue
  ) => {
    setBreadcrumbsStatus(prev => {
      const next = {
        ...prev,
        [firstKey]: progresses[firstValue] || progresses.default,
      };

      if (secondKey && secondValue !== undefined) {
        next[secondKey] = progresses[secondValue] || progresses.default;
      }

      return next;
    });
  };

  const handleTriggerSave = (step, exit, next) => {
    console.log('campaignStep', campaignStep);
    console.log('value/step', step);
    let current = step;
    const nextStep = next;

    if ([
      'TrackingSetup',
      'InstallTrackingCode',
      'TrackingEvents',
      'ConfigureTrackingEvents'
    ].includes(step)) {
      current = 'TrackingSetup';
    }

    setSave({
      exit,
      step: current,
      next: nextStep,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Root>
        <AppHeader
          triggerSave={handleTriggerSave}
          hidenav="true"
          navSkipSaveStatus={navSkipSaveStatus}
          isSaveButtonDisabled={formState.isDirty && !formState.isValid}
          step={campaignStep}
        >
          <Container
            maxWidth="lg"
            className={clsx(classes.container, 'SetupWizard')}
          >
            <Paper
              className={clsx(classes.paper, '--background-colorbar')}
              elevation={12}
            >
              {isLoading && (
                <Box
                  display="flex"
                  width="100%"
                  height="750px"
                  minHeight="100%"
                  justifyContent="center"
                  alignItems="center"
                  px={10}
                >
                  <CircularProgress color="secondary" />
                </Box>
              )}
              {!isLoading && (
                <>
                  <Breadcrumbs
                    className="Breadcrumbs"
                    breadcrumbsStatus={breadcrumbsStatus}
                    title={adContext.theme === Themes.NBCU ? `Create Campaign` : `Create campaign`}
                    lastStepElement={adContext.theme === Themes.DEFAULT ? <img src={require('../../images/rocket/rocket.png')} /> : null}
                  />

                  <Box
                    px={inventoryOverlay ? 0 : 8}
                    pr={hasSidePanel ? 0 : 8}
                    position="relative"
                  >
                    <Fade in={!isLoading} timeout={750}>
                      <Grid
                        container
                        style={{
                          minHeight: 634,
                        }}
                      >
                        <Grid
                          className={classes.gridContainer}
                          item
                          xs={hasSidePanel ? 9 : 12}
                        >
                          <Box pr={4} pt={4} pb={6} style={{ height: '100%' }}>
                            {step === SetupWizardSteps.GEO_TARGETING && (
                              <GeoTargeting
                                showOverlay={showOverlay}
                                targetEntireUS={selectEntireUS}
                                setStep={setStep}
                                setTargetEntireUS={setSelectEntireUS}
                                breadcrumbsStatus={breadcrumbsStatus}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                handleCampaignPanel={handleCampaignPanel}
                                geoResults={geo}
                                setGeoResults={setGeoResults}
                                currentAdGroup={currentAdGroup}
                                setCurrentAdGroup={setCurrentAdGroup}
                                campaignUrl={campaignUrl}
                                advertiserId={adContext.id}
                                combinedTargetingData={combinedTargetingData}
                                save={save}
                                triggerSave={handleTriggerSave}
                              />
                            )}

                            {step === SetupWizardSteps.DEMO_TARGETING && (
                              <DemoTargeting
                                isAdvanced={hasAdvancedTargeting}
                                setIsAdvanced={handleHasAdvancedTargeting}
                                breadcrumbsStatus={breadcrumbsStatus}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                handleDemoData={handleDemoData}
                                setStep={setStep}
                                advertiserId={adContext.id}
                                currentAdGroup={currentAdGroup}
                                combinedTargetingData={combinedTargetingData}
                                age={demoData.age}
                                gender={demoData.gender}
                                income={demoData.income}
                                save={save}
                                triggerSave={handleTriggerSave}
                                setAdjustRecommendedBid={setAdjustRecommendedBid}
                                setDemographicsTouched={setDemographicsTouched}
                                setIsDefaultDemo={setIsDefaultDemo}
                              />
                            )}

                            {step === SetupWizardSteps.TARGETING_SEGMENTS && (
                              <MemoTargetingSegments
                                breadcrumbsStatus={breadcrumbsStatus}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                handleSegmentsData={handleSegmentsData}
                                setStep={setStep}
                                advanced={hasAdvancedTargeting}
                                combinedTargetingData={combinedTargetingData}
                                currentAdGroup={currentAdGroup}
                                segments={segments}
                                setSegments={setSegments}
                                setIsNoBidFees={setIsNoBidFees}
                                save={save}
                                triggerSave={handleTriggerSave}
                                setAdjustRecommendedBid={setAdjustRecommendedBid}
                                adjustRecommenedBid={adjustRecommendedBid}
                              />
                            )}

                            {step === SetupWizardSteps.INVENTORY && (
                              <Inventory
                                advertiserHasPrivateDeals={advertiserHasPrivateDeals}
                                showSubLinks={adContext.theme === Themes.DEFAULT}
                                showAdvanced
                                title={adContext.theme === Themes.NBCU ? 'Select NBCU Streaming Inventory' : 'Select Inventory'}
                                hasAdvancedTargeting={hasAdvancedTargeting}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                onDeviceOverlayShow={setIsDeviceOverlay}
                                setIsCustomTargeting={setIsCustomTargeting}
                                tier={tier}
                                setTier={setTier}
                                setStep={setStep}
                                canNext={canSaveProfile}
                                onSubmit={handleInventorySave}
                                setInventoryOverlay={setInventoryOverlay}
                                inventoryOverlay={inventoryOverlay}
                                showGenre={adContext.theme === Themes.NBCU}
                                showRunOfNetwork={adContext.theme === Themes.NBCU}
                                showBrandLogos={adContext.theme === Themes.DEFAULT}
                                showCustomApp={adContext.theme === Themes.DEFAULT}
                                isLoading={isLoading}
                              />
                            )}

                            {step === SetupWizardSteps.BUNDLES && (
                              <SelectCategories
                                screenSize={screenSize}
                                hasAdvancedTargeting={hasAdvancedTargeting}
                                breadcrumbsStatus={breadcrumbsStatus}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                handleInventoryData={handleInventoryData}
                                isDefaultDemo={isDefaultDemo}
                                isNoBidFees={isNoBidFees}
                                tier={tier}
                                setTier={setTier}
                                setStep={setStep}
                                currentAdGroup={currentAdGroup}
                                advertiserId={adContext.id}
                                combinedTargetingData={combinedTargetingData}
                                bundles={bundles}
                                setBundles={setBundles}
                                save={save}
                                canNext={canSaveProfile}
                                triggerSave={handleTriggerSave}
                                setCpmRange={setCpmRange}
                              />
                            )}

                            {step === SetupWizardSteps.GENRES && (
                              <SelectGenres
                                screenSize={screenSize}
                                hasAdvancedTargeting={hasAdvancedTargeting}
                                breadcrumbsStatus={breadcrumbsStatus}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                handleInventoryData={handleInventoryData}
                                isDefaultDemo={isDefaultDemo}
                                isNoBidFees={isNoBidFees}
                                tier={tier}
                                setTier={setTier}
                                setStep={setStep}
                                currentAdGroup={currentAdGroup}
                                combinedTargetingData={combinedTargetingData}
                                bundles={bundles}
                                setBundles={setBundles}
                                save={save}
                                canNext={canSaveProfile}
                                triggerSave={handleTriggerSave}
                                setCpmRange={setCpmRange}
                                showGenre={(
                                  adContext &&
                                  adContext.theme &&
                                  adContext.theme === Themes.NBCU
                                )}
                              />
                            )}

                            {step === SetupWizardSteps.PRIVATE && (
                              <SelectDeals
                                screenSize={screenSize}
                                breadcrumbsStatus={breadcrumbsStatus}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                handleInventoryData={handleInventoryData}
                                isNoBidFees={isNoBidFees}
                                isDefaultDemo={isDefaultDemo}
                                tier={tier}
                                setTier={setTier}
                                setStep={setStep}
                                currentAdGroup={currentAdGroup}
                                combinedTargetingData={combinedTargetingData}
                                bundles={deals}
                                setBundles={setDeals}
                                save={save}
                                canNext={canSaveProfile}
                                triggerSave={handleTriggerSave}
                                backButton={
                                  <IconButton
                                    size="small"
                                    onClick={() => setStep("Inventory")}
                                  >
                                    <ArrowBackIosIcon fontSize="small" />
                                  </IconButton>
                                }
                              />
                            )}

                            {step === SetupWizardSteps.NAME_AD_GROUP && (
                              <NameAdGroup
                                cpmRange={cpmRange}
                                currentAdGroup={currentAdGroup}
                                handleCurrentAdGroup={handleCurrentAdGroup}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                noEndDate={noEndDate}
                                selectedEndDateCampaign={selectedEndDate}
                                selectedEndTimeCampaign={selectedEndTime}
                                selectedStartDateCampaign={selectedStartDate}
                                selectedStartTimeCampaign={selectedStartDate}
                                setIsDayParting={setIsDayParting}
                                setIsAdGroup={handleIsAdGroup}
                                setStep={setStep}
                                save={save}
                                triggerSave={handleTriggerSave}
                                adjustRecommendedBid={adjustRecommendedBid}
                                setInventoryOverlay={setInventoryOverlay}
                                setIsFreqCaps={setIsAGFreqCaps}
                                freqCaps={agFreqCaps}
                                currentDayPartsCopy={CurrentDayPartsCopiesThemes[adContext.theme]}
                                combinedTargetingData={combinedTargetingData}
                                deliveryTime={deliveryTime}
                                setDeliveryTime={setDeliveryTime}
                                dayParts={dayParts}
                              />
                            )}

                            {step === SetupWizardSteps.CREATIVE_UPLOAD && (
                              <CreativeUploader
                                experimentStatus={experimentStatus}
                                handleAssign={handleAssignCreatives}
                                handleUnassign={handleUnassignCreative}
                                onSubmit={handleSubmit(handleCreativeUploaderSubmit)}
                                isDisplay={isDisplay && adGroups.length === 0}
                                isExperiment={campaign.isExperiment}
                                isLoading={isUploading}
                                setIsLoading={setIsUploading}
                                isSubmitDisabled={
                                  (formState.isDirty && !formState.isValid)
                                  || !creatives.length
                                  || isIncremental
                                }
                                showVastTag={showVastTag}
                                setCurrentPreview={setCurrentPreview}
                                setIsCreativePreview={setIsCreativePreview}
                                next={adContext.theme === Themes.NBCU ? 'Save & Create a New Ad Group' : 'Save & Create A New Ad Group'}
                                onBack={() => {
                                  handleCampaignPanel(true);
                                  setStep('NameAdGroup');

                                  if (creatives.length > 0) {
                                    handleBreadcrumbsStatus('creative', 50, 'targeting', 75)
                                  } else {
                                    handleBreadcrumbsStatus('creative', -1, 'targeting', 75);
                                  }
                                }}
                              />
                            )}

                            {step === SetupWizardSteps.DISPLAY_UPLOAD && (
                              <DisplayUploader
                                isSubmitDisabled={(formState.isDirty && !formState.isValid) || !displays.length}
                                onSubmit={handleSubmit(handleDisplayUploaderSubmit)}
                                handleSaveAdGroup={handleSaveAdGroup}
                                handleBreadcrumbsStatus={handleBreadcrumbsStatus}
                                handleCampaignPanel={handleCampaignPanel}
                                setIsAdGroup={handleIsAdGroup}
                                setStep={setStep}
                                setCurrentPreview={setCurrentPreview}
                                setIsDisplayPreview={setIsDisplayPreview}
                                displayLineItemId={displayLineItemId}
                                setDisplayLineItemId={setDisplayLineItemId}
                                onBack={() => {
                                  setStep('CreativeUpload');
                                  handleBreadcrumbsStatus('creative', -1, 'targeting', 75);
                                }}
                              />
                            )}

                            {step === SetupWizardSteps.TRACKING_SETUP && (
                              <TrackingSetup
                                handleBreadcrumbsStatus={handleBreadcrumbsStatus}
                                handleCampaignPanel={handleCampaignPanel}
                                handleCurrentAdGroup={handleCurrentAdGroup}
                                handleEditAdGroup={handleEditAdGroup}
                                handleRemoveLastAdGroup={handleRemoveLastAdGroup}
                                isDisplay={isDisplay}
                                setIsAdGroup={setIsAdGroup}
                                setStep={setStep}
                                setTrackingMethod={setTrackingMethod}
                                trackingMethod={trackingMethod}
                              />
                            )}

                            {step === SetupWizardSteps.INSTALL_TRACKING_CODE && (
                              <InstallTrackingCode
                                handleInstallCompleted={handleInstallCompleted}
                                handleInstallLater={handleInstallLater}
                                isCampaign
                                installCompleted={installCompleted}
                                installLater={installLater}
                                ecommerce={ecommerce}
                                setEcommerce={setEcommerce}
                                exposureLid={adContext.exposureLid}
                                outcomeLid={adContext.outcomeLid}
                                selectedEvents={selectedEvents}
                                setStep={setStep}
                                trackingMethod={trackingMethod}
                                triggerSave={handleTriggerSave}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                              />
                            )}

                            {step === SetupWizardSteps.TRACKING_EVENTS && (
                              <TrackingEvents
                                selectedEvents={selectedEvents}
                                handleSelectEvent={handleSelectEvent}
                                breadcrumbsStatus={breadcrumbsStatus}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                setStep={setStep}
                                totalEvents={totalEvents}
                              />
                            )}

                            {step === SetupWizardSteps.LAUNCH_CAMPAIGN && (
                              <LaunchCampaign
                                adGroups={adGroups}
                                billingMethod={adContext.billing_method}
                                breadcrumbsStatus={breadcrumbsStatus}
                                creatives={allSelectedCreatives}
                                handleCampaignData={handleFinalizeCampaignData}
                                displays={displays}
                                displayPercent={display.percent}
                                handleAdGroupsData={handleAdGroupsData}
                                handleDisplaysData={handleDisplaysData}
                                isDisplay={isDisplay}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                isPurchaseOrder={
                                  adContext &&
                                  adContext.theme &&
                                  adContext.theme === Themes.NBCU
                                }
                                startDate={moment(selectedStartDate).format("MMMM D[,] YYYY")}
                                setStep={setStep}
                                save={save}
                                tracking={{ trackingMethod, selectedEvents }}
                              />
                            )}

                            {step === 'PostLaunch' && (
                              <Redirect to="/" />
                            )}

                            {!SetupWizardStepList.includes(step) && (
                              <CreateNewCampaign
                                campaign={campaign}
                                campaignName={campaignName}
                                defaultValues={{ 'advertiser-name': adContext.name }}
                                setCampaignName={setCampaignName}
                                companyName={props.companyName}
                                advertiserName={advertiserName}
                                amount={amount}
                                showDisplay={adContext.theme === Themes.DEFAULT}
                                showTestIncrementality={
                                  adContext.theme === Themes.DEFAULT
                                  || (
                                    adContext.theme === Themes.NBCU
                                    && !!campaign.incremental.holdOut
                                  )
                                }
                                goalsList={GoalsListThemes[adContext.theme]}
                                handleUpdateAmount={handleUpdateAmount}
                                selectedStartDate={selectedStartDate}
                                selectedStartTime={selectedStartTime}
                                handleUpdateStartDate={handleUpdateStartDate}
                                handleUpdateStartTime={handleUpdateStartTime}
                                selectedEndDate={selectedEndDate}
                                selectedEndTime={selectedEndTime}
                                handleUpdateEndDate={handleUpdateEndDate}
                                handleUpdateEndTime={handleUpdateEndTime}
                                isDailyInputError={isDailyInputError}
                                isPercentageError={isPercentageError}
                                noEndDate={noEndDate}
                                handleNoEndDateChange={handleNoEndDateChange}
                                enableDateTimeInput={enableDateTimeInput}
                                testIncrementally={testIncrementally}
                                handleTestIncrementally={handleTestIncrementally}
                                dayParts={dayParts}
                                handleDailyBudget={handleDailyBudget}
                                setDayParting={setIsDayParting}
                                setStep={setStep}
                                breadcrumbsStatus={breadcrumbsStatus}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                handleCampaignPanel={handleCampaignPanel}
                                setIsAdGroup={handleIsAdGroup}
                                handleSaveCampaign={handleSaveCampaign}
                                save={save}
                                triggerSave={handleTriggerSave}
                              />
                            )}

                            {inventoryOverlay && (
                              <SelectCategories
                                hasAdvancedTargeting={hasAdvancedTargeting}
                                breadcrumbsStatus={breadcrumbsStatus}
                                updateBreadcrumbs={handleBreadcrumbsStatus}
                                handleInventoryData={handleInventoryData}
                                isDefaultDemo={isDefaultDemo}
                                isNoBidFees={isNoBidFees}
                                tier={tier}
                                setTier={setTier}
                                setStep={setStep}
                                currentAdGroup={currentAdGroup}
                                advertiserId={adContext.id}
                                combinedTargetingData={combinedTargetingData}
                                bundles={bundles}
                                setBundles={setBundles}
                                save={save}
                                canNext={canSaveProfile}
                                triggerSave={handleTriggerSave}
                                setCpmRange={setCpmRange}
                                setInventoryOverlay={setInventoryOverlay}
                                inventoryOverlay={inventoryOverlay}
                              />
                            )}

                            {/* TODO: Refactor previews into one `MediaPreview` component */}
                            {isCreativePreview && (
                              <Box className={classes.preview}>
                                <CreativePreview
                                  handleClose={handleClosePreview}
                                  name={currentPreview.name}
                                  url={currentPreview.preview_url}
                                />
                              </Box>
                            )}

                            {isDisplayPreview && (
                              <Box className={classes.preview}>
                                <DisplayPreview
                                  handleClose={handleClosePreview}
                                  name={currentPreview.name}
                                  url={currentPreview.preview_url}
                                />
                              </Box>
                            )}
                          </Box>
                        </Grid>

                        {hasSidePanel && (
                          <Grid style={{ background: 'white' }} container item xs={3}>
                            <CampaignPanel
                              adGroups={adGroups.map(group => {
                                const { targeting } = group;
                                const list = getTargetingList(targeting, allowedTargeting);

                                return {
                                  ...group,
                                  targeting: {
                                    ...targeting,
                                    [TargetingTypeKeys[TargetingTypes.DEVICE]]: list[TargetingTypes.DEVICE],
                                    [TargetingTypeKeys[TargetingTypes.OS]]: list[TargetingTypes.OS],
                                  },
                                };
                              })}
                              budget={amount}
                              isDisplay={isDisplay}
                              showDisplay={adContext.theme === Themes.DEFAULT}
                              demoData={demoData}
                              deviceTypes={deviceTypes}
                              operatingSystems={operatingSystems}
                              formatGeoName={formatGeoName}
                              isLoading={isUploading}
                              handleCampaignPanel={handleCampaignPanel}
                              handleSaveAdGroup={handleSaveAdGroup}
                              removeCurrentAdGroup={handleRemoveCurrentAdGroup}
                              hasDayParting={dayParts.length > 0}
                              geoResults={geo}
                              name={campaignName}
                              targetingData={targetingData}
                              selectedEndDate={selectedEndDate}
                              selectedEndTime={selectedEndTime}
                              selectedStartDate={selectedStartDate}
                              selectedStartTime={selectedStartTime}
                              setStep={setStep}
                              noEndDate={noEndDate}
                              isAdGroup={isAdGroup}
                              isSubmitDisabled={
                                (formState.isDirty && !formState.isValid)
                                || isUploading
                                || hasCompletedAdGroups
                                || (step !== "CreativeUpload" && step !== "DisplayUpload")
                                || (campaign.isExperiment && !experimentStatus.completed)
                              }
                              setIsAdGroup={handleIsAdGroup}
                              updateBreadcrumbs={handleBreadcrumbsStatus}
                              currentAdGroup={currentAdGroup}
                              advertiserId={adContext.id}
                              onSubmit={handleSubmit(handleCampaignPanelSubmit)}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Fade>
                    {overlayShown && (
                      <UploadGeo
                        showOverlay={showOverlay}
                        setGeoResults={setGeoResults}
                        targetEntireUS={selectEntireUS}
                        setTargetEntireUS={setSelectEntireUS}
                      />
                    )}

                    {isDeviceOverlay && <DeviceOverlay onClose={setIsDeviceOverlay} />}
                  </Box>

                  {isDayParting && (
                    <DayParting
                      dayParts={dayParts}
                      setDayParts={handleDayParts}
                      setDayParting={setIsDayParting}
                      title={`Configure ${adContext.theme === Themes.NBCU ? 'dayparting' : 'day-parting'}`}
                    />
                  )}

                  {campaign.isCapAds && (
                    <FrequencyCaps
                      setIsFreqCaps={campaign.handleCapAds}
                      freqCaps={adCap.freqCaps}
                      setFreqCaps={adCap.setFreqCaps}
                    />
                  )}

                  {isAGFreqCaps && (
                    <FrequencyCaps
                      setIsFreqCaps={setIsAGFreqCaps}
                      freqCaps={agFreqCaps}
                      setFreqCaps={setAGFreqCaps}
                    />
                  )}

                  {isCustomTargeting && (
                    <CustomAppTargeting
                      setIsCustomTargeting={setIsCustomTargeting}
                      customTargeting={customTargeting}
                      setCustomTargeting={setCustomTargeting}
                    />
                  )}
                </>
              )}
            </Paper>
          </Container>
        </AppHeader>
      </Root>
    </FormProvider>
  );
}

SetupCampaignWizard.propTypes = {
  companyName: PropTypes.string,
};

export default SetupCampaignWizard;
