import moment from 'moment-timezone';
import { convertToStatus, getValues, removeNullValues } from '../../utils';
import { extractIdFromUrl } from '../../../../../cypress/utils/helpers';
import { accountCategoriesObjectiveMap } from '../../../accountCategories';
import { fields } from '@v2/components/forms/CampaignForm/constants';

export const parseApiDataToFormValues = values => {
  const [
    startDate,
    endDate,
    budget,
    objective,
    dailyBudget,
    experimentType,
    experimentPlan,
    freqCaps = [],
    displayPct,
  ] = getValues(values, [
    fields.startDate.path,
    fields.endDate.path,
    fields.budget.path,
    fields.objective.path,
    fields.dailyBudget.path,
    'experimentType',
    'experimentPlan',
    'freqCaps',
    fields.displayPct.path,
  ]);

  const defaultEndDate = moment().add(1, 'month').endOf('month');
  const defaultEndTime = moment(defaultEndDate).endOf('day');

  return {
    ...values,
    [fields.objective.path]: objective ?? 'audienceReach',
    [fields.status.path]: convertToStatus(values),
    [fields.startDate.path]: moment(startDate ?? undefined),
    [fields.startTime.path]: startDate ? moment(startDate) : moment(),
    [fields.endDate.path]: endDate ? moment(endDate) : defaultEndDate,
    [fields.endTime.path]: endDate ? moment(endDate) : defaultEndTime,
    [fields.budgetType.path]: 'daily_budget',
    [fields.lifetimeBudget.path]: budget ?? undefined,
    [fields.dailyBudget.path]: dailyBudget ?? undefined,
    [fields.advancedOptions.path]: {
      isIncremental: !!experimentType,
      isExperiment: !!experimentPlan,
      isCapAds: freqCaps.length > 0,
      freqCaps,
      experimentPlan: experimentPlan ?? undefined,
      experimentType: experimentType ?? undefined,
    },
    displayPct: Number(displayPct) ? true : false,
  };
};

export const parseFormValuesToApiData = values => {
  const {
    name,
    advertiser,
    startDate,
    startTime,
    endDate,
    endTime,
    budgetType,
    dailyBudget,
    lifetimeBudget,
    objective,
    advancedOptions,
    active,
    draft,
    displayPct,
  } = values || {};

  const {
    isIncremental,
    isExperiment,
    isCapAds,
    experimentPlan,
    experimentType,
    freqCaps,
  } = advancedOptions || {};

  return {
    name,
    budget: budgetType === 'lifetime_budget' ? lifetimeBudget : dailyBudget,
    budget_type: budgetType,
    start_date: combineDateAndTime(startDate, startTime ?? moment()),
    end_date: combineDateAndTime(endDate, endTime),
    experiment_plan: isExperiment ? experimentPlan : undefined,
    experiment_type: isIncremental ? experimentType : undefined,
    freq_caps: isCapAds ? removeNullValuesFromFreqCaps(freqCaps) : [],
    advertiser,
    active: active !== undefined ? !!active : false,
    draft: draft !== undefined ? !!draft : true,
    objective,
    // TODO: update displayPct once display adgroup budget is set
    display_pct: displayPct ? 0.1 : 0,
  };
};

export const getRecommendedCampaignObjectForAdvertiser = advertiser => {
  if (advertiser?.category) {
    const categoryId = extractIdFromUrl(advertiser.category);
    if (categoryId) {
      return Object.keys(accountCategoriesObjectiveMap).find(key => {
        return accountCategoriesObjectiveMap[key].includes(
          parseInt(categoryId, 10),
        );
      });
    }
  }
  return null;
};

const combineDateAndTime = (date, time) => {
  if (!date || !time) return null;
  const dateMoment = moment(date);
  const timeMoment = moment(time);

  const combinedMoment = moment({
    year: dateMoment.year(),
    month: dateMoment.month(),
    day: dateMoment.date(),
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    second: timeMoment.second(),
  });

  return combinedMoment;
};

const removeNullValuesFromFreqCaps = freqCaps => {
  return freqCaps.map(freqCap => {
    return removeNullValues(freqCap);
  });
}
